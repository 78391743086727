<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Other Property</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="additional-form">
          <v-switch
            label="Completed"
            v-model="fields.completed"
            inset
            :error="errors.hasOwnProperty('completed')"
            :error-messages="errors['completed']"
          ></v-switch>

          <v-textarea
            label="Text"
            v-model="fields.text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('text')"
            :error-messages="errors['text']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="additional-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      fields: {
        text: null,
        completed: false,
      },

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    open: function (repair, addition = null) {
      if (addition !== null) {
        this.addition = addition;
        this.fields.completed = addition.completed;
        this.fields.text = addition.text;
        this.isEditing = true;
      }

      this.repair = repair;
      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        repairId: this.repair.id,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.additionId = this.addition.id;
      }

      this.$store
        .dispatch("sbpm/repairsStore/saveRepairAddition", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields = {
        text: null,
        completed: false,
      };
    },
  },
};
</script>
