<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-craigtoun-memberships' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Memberships</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>
            {{ membership.customer.full_name }}
          </h1>
        </v-col>
        <v-col cols="auto">
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="orange lighten-4 orange--text"
                class="mr-2"
                v-on="on"
                @click="$refs.membershipDialog.open(membership, true)"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Renew</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.membershipDialog.open(membership)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Basic Details</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Customer</th>
                  <td>
                    <router-link
                      :to="{
                        name: 'module-craigtoun-customers-individual',
                        params: { customerId: membership.customer.id },
                      }"
                      >{{ membership.customer.full_name }}</router-link
                    >
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <v-chip
                      v-if="
                        new Date().toISOString().substring(0, 10) >
                        membership.valid_to
                      "
                      small
                      label
                      color="red"
                      text-color="white"
                      >Expired</v-chip
                    >
                    <v-chip v-else small label>{{ membership.status }}</v-chip>
                  </td>
                </tr>

                <tr>
                  <th>Cost</th>
                  <td>£{{ membership.cost }}</td>
                </tr>
                <tr>
                  <th>Valid From</th>
                  <td>{{ membership.formatted_dates.valid_from }}</td>
                </tr>
                <tr v-if="membership.valid_to">
                  <th>Valid To</th>
                  <td>{{ membership.formatted_dates.valid_to }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <membership-dialog ref="membershipDialog" />
  </div>
</template>

<script>
import MembershipDialog from "./components/MembershipDialog";

import { craigtoun } from "../../mixins";

export default {
  mixins: [craigtoun],

  props: {
    appColor: {
      type: String,
    },
  },

  components: {
    MembershipDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Memberships",
          disabled: false,
          to: { name: "module-craigtoun-memberships" },
          exact: true,
        },
        {
          text: "Membership",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    membership() {
      return this.$store.getters["craigtoun/membershipsStore/get"];
    },
  },
};
</script>
