<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-sbpm-moveout' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Moveout</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="pr-12">
          <h1>Moveout</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.moveOutDialog.openForm(moveout)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5">
              <tbody>
                <tr>
                  <td>Property</td>
                  <td>
                    {{ moveout.property.property_name }}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ moveout.status }}</td>
                </tr>
                <!-- <tr>
                  <td>Description</td>
                  <td><div v-html="moveout.description" /></td>
                </tr> -->
                <!-- <tr>
                  <td>Created By</td>
                  <td>{{ moveout.user.full_name }}</td>
                </tr> -->
                <tr>
                  <td>Assignee</td>
                  <td>
                    <div v-if="moveout.assignee">
                      {{ moveout.assigne_user.full_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Move out Date</td>
                  <td>
                    {{ moveout.due_date ? formatDate(moveout.due_date) : "" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="8">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Checklist</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Item</th>
                  <th>Complete</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>

                <tr v-for="item in moveout.checklists" v-bind:key="item.id">
                  <th>{{ item.checklist_items.name }}</th>
                  <td>
                    <v-icon v-if="item.completed" color="green"
                      >mdi-check-circle
                    </v-icon>
                    <v-icon v-else color="red">mdi-close-circle </v-icon>
                  </td>
                  <td>{{ item.notes }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.checkListDialog.openForm(2, item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Notes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(moveout)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="moveout.notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Audit</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="moveout.audits"
              no-data-text="No Audit"
              :items-per-page="-1"
            >
              <template v-slot:item.audit_date="{ item }">
                {{ formatDateTime(item.audit_date, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <MoveOutDialog ref="moveOutDialog" :fromDashboard="true" />
      <CheckListDialog ref="checkListDialog" type="moveout" />
      <NoteDialog ref="noteDialog" type="moveout" />
    </v-container>
  </div>
</template>

<script>
import MoveOutDialog from "../managed-properties/components/MoveOutDialog.vue";
import CheckListDialog from "../checklists/components/CheckListDetailDialog.vue";
import NoteDialog from "../notes/NoteDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: { CheckListDialog, NoteDialog, MoveOutDialog },

  data() {
    return {
      pdfLoading: false,

      fields: {
        attending_viewing: false,
      },
      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],

      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
      breadcrumbs: [
        {
          text: "Moveout",
          disabled: false,
          to: { name: "module-sbpm-moveout" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    moveout() {
      return this.$store.getters["sbpm/moveoutStore/get"];
    },
  },
  methods: {},
};
</script>
