import store from "@/stores/";

export default [
  {
    path: "offers",
    component: () => import("@/modules/petroshops/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("users/loadModule", {
          appId: to.params.id,
          module: "users",
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-ps-offers",
        component: () => import("@/modules/petroshops/views/offers/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("petroshops/offersStore/loadOffers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-ps-offers-archived",
        component: () => import("@/modules/petroshops/views/offers/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("petroshops/offersStore/loadOffers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":offerId",
        name: "module-ps-offers-individual",
        component: () => import("@/modules/petroshops/views/offers/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("petroshops/offersStore/loadOffer", {
              appId: to.params.id,
              offerId: to.params.offerId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
