<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"> Add a new Property </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="property-add-form">
          <v-text-field
            label="Address"
            v-model="fields.property_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_name')"
            :error-messages="errors['property_name']"
          ></v-text-field>

          <v-text-field
            label="SME ID"
            v-model="fields.sme_id"
            type="text"
            prefix="P"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('sme_id')"
            :error-messages="errors['sme_id']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="property-add-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,

      property: {},
      fields: {
        property_name: null,
        sme_id: null,
      },
      errors: {},
    };
  },

  computed: {},

  methods: {
    open() {
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("sbpm/propertiesStore/saveProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;

      this.property = {};
      this.fields = {
        property_name: null,
        sme_id: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
