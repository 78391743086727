<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Bookings</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Booking"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="info"
              class="mr-2"
              :to="{ name: 'module-craigtoun-venues' }"
            >
              <v-icon left>mdi-calendar-multiselect</v-icon>
              Calendar View
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Bookings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    v-on="on"
                    class="mr-2"
                    :to="{ name: 'module-craigtoun-venues-list-past' }"
                  >
                    Past Bookings
                  </v-btn>
                </template>
                <span>View Archived Bookings</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    v-on="on"
                    class="mr-2"
                    :to="{ name: 'module-craigtoun-venues-list-archived' }"
                  >
                    <v-icon small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>View Archived Bookings</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.eventDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Hire</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="eventsTableHeaders"
              :items="events"
              no-data-text="No Bookings can be found"
            >
              <template v-slot:item.date="{ item }">
                {{ item.formatted_dates.date }}
              </template>
              <template v-slot:item.venue="{ item }">
                {{ item.venue.name }}
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-craigtoun-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  v-if="item.customer_id"
                  >{{ item.customer.full_name }}</router-link
                >
                <div v-else>{{ item.customer_name }}</div>
              </template>
              <template v-slot:item.has_responded="{ item }">
                <v-chip
                  label
                  small
                  color="green white--text"
                  v-if="item.has_responded"
                  >Yes</v-chip
                >
                <v-chip label small color="red white--text" v-else>No</v-chip>
              </template>
              <template v-slot:item.payment_complete="{ item }">
                <v-chip
                  label
                  small
                  color="green white--text"
                  v-if="item.status == 'active'"
                  >Yes</v-chip
                >
                <v-chip label small color="red white--text" v-else>No</v-chip>
                <span v-if="item.status != 'active'">
                  {{ item.status ? item.status : "N/A" }}</span
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  dark
                  x-small
                  depressed
                  class="mr-2"
                  :loading="pdfLoading"
                  @click="downloadPdf(item)"
                >
                  <v-icon x-small>mdi-file-pdf-box</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.eventDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="openDelete(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="4"> </v-col>
      </v-row>
    </v-container>
    <venue-season-dialog ref="venueSeasonDialog" />
    <event-dialog ref="eventDialog" :isNotVenuePage="false" />
    <v-dialog v-model="deleteBooking.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Event</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteBooking.booking.id }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteBooking.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventDialog from "../components/EventDialog";
import VenueSeasonDialog from "../components/VenueSeasonDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },

  components: {
    EventDialog,
    VenueSeasonDialog,
  },

  data() {
    return {
      pdfLoading: false,
      breadcrumbs: [
        {
          text: "Venue Hires",
          disabled: false,
          to: {
            name: "module-craigtoun-venues",
          },
          exact: true,
        },
        {
          text: "Venues",
          disabled: false,
          to: {
            name: "module-craigtoun-venues-list",
          },
          exact: true,
        },
        {
          text: "Venue",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      eventsTableHeaders: [
        { text: "Date", value: "date" },
        { text: "Venue", value: "venue" },
        { text: "Customer", value: "customer" },
        { text: "Total Attendees", value: "total_attendees" },
        { text: "Start Time", value: "start_time" },
        { text: "Craigtoun Responded?", value: "has_responded" },
        { text: "Payment Complete", value: "payment_complete" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteBooking: {
        dialog: false,
        booking: {},
        loading: false,
      },
    };
  },

  computed: {
    events() {
      let bookings = this.$store.getters["craigtoun/venuesStore/bookingsAll"];
      let date = new Date();

      bookings = bookings.filter((item) => {
        const itemDate = new Date(item.date);
        if (date) {
          return date <= itemDate;
        }
      });

      if (this.searchTerm !== "") {
        bookings = bookings.filter((c) => {
          const name = c.customer_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();
          return name.includes(searchTerm);
        });
      }

      return bookings;
    },
  },

  methods: {
    openDelete(booking) {
      this.deleteBooking.booking = booking;
      this.deleteBooking.dialog = true;
    },

    resetDelete() {
      this.deleteBooking.dialog = false;
      this.deleteBooking.booking = {};
      this.deleteBooking.loading = false;
    },

    saveDelete() {
      this.deleteBooking.loading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/deleteBooking", {
          appId: this.$route.params.id,
          bookingId: this.deleteBooking.booking.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteBooking.loading = false;
        });
    },

    downloadPdf(booking) {
      const appId = this.$route.params.id;
      const bookingId = booking.id;
      this.pdfLoading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/downloadBookingPdf", {
          appId,
          bookingId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "booking.pdf";
          link.click();
          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },
  },
};
</script>
