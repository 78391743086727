<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-btn small depressed @click="$router.go(-1)"
          ><v-icon left>mdi-arrow-left</v-icon>Back</v-btn
        >
      </v-row>

      <v-row>
        <v-col cols="2" class="pr-12">
          <h1>Enquiry</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Property Interested</th>
                  <td>{{ enquiry.property_interested }}</td>
                </tr>
                <tr>
                  <th>First Name</th>
                  <td>{{ enquiry.first_name }}</td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td>{{ enquiry.last_name }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ enquiry.email }}</td>
                </tr>
                <tr>
                  <th>Mobile</th>
                  <td>{{ enquiry.mobile }}</td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>{{ enquiry.age }}</td>
                </tr>
                <tr>
                  <th>Address Line</th>
                  <td>{{ enquiry.address_line_1 }}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{{ enquiry.city }}</td>
                </tr>
                <tr>
                  <th>Country</th>
                  <td>{{ enquiry.country }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Reason For Moving Property</th>
                  <td>{{ enquiry.reason_moving }}</td>
                </tr>
                <tr>
                  <th>Employment Status</th>
                  <td>{{ enquiry.employment_status }}</td>
                </tr>
                <!-- <tr>
                  <th>Employer</th>
                  <td>{{ enquiry.employer }}</td>
                </tr>
                <tr>
                  <th>Occupation</th>
                  <td>{{ enquiry.occupation }}</td>
                </tr>
                <tr>
                  <th>University</th>
                  <td>{{ enquiry.university }}</td>
                </tr>
                <tr>
                  <th>Undergraduate</th>
                  <td>{{ enquiry.undergraduate }}</td>
                </tr> -->
                <tr>
                  <th>Total People</th>
                  <td>{{ enquiry.total_people }}</td>
                </tr>
                <tr>
                  <th>Occupation Details</th>
                  <td>{{ enquiry.occupation_details }}</td>
                </tr>
                <tr>
                  <th>Relationship</th>
                  <td>{{ enquiry.relationship }}</td>
                </tr>
                <tr>
                  <th>Smokers</th>
                  <td>{{ enquiry.smokers ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Pets</th>
                  <td>{{ enquiry.pets ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Pets Details</th>
                  <td>{{ enquiry.pets_details }}</td>
                </tr>
                <tr>
                  <th>How Long Stay</th>
                  <td>{{ enquiry.how_long_stay }}</td>
                </tr>
                <tr>
                  <th>Date of Entry</th>
                  <td>{{ enquiry.date_of_entry }}</td>
                </tr>
                <tr>
                  <th>Data Protection</th>
                  <td>{{ enquiry.data_protection }}</td>
                </tr>
                <tr>
                  <th>Try Match</th>
                  <td>{{ enquiry.try_match }}</td>
                </tr>
                <tr>
                  <th>Rental Budget</th>
                  <td>{{ enquiry.rental_budget }}</td>
                </tr>
                <tr>
                  <th>Number of bedrooms</th>
                  <td>{{ enquiry.number_of_bedrooms }}</td>
                </tr>
                <tr>
                  <th>Require Furnished</th>
                  <td>{{ enquiry.require_furnished }}</td>
                </tr>
                <tr>
                  <th>Latest Date</th>
                  <td>{{ formatDate(enquiry.latest_date) }}</td>
                </tr>
              </tbody></v-simple-table
            ></v-card
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {},

  data() {
    return {
      pdfLoading: false,
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      fields: {
        attending_viewing: false,
      },
      activeHover: false,
      myIcon: {
        name: "mdi-unfold-less-vertical",
        color: "default",
      },

      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],

      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
      breadcrumbs: [
        {
          text: "Enquiries",
          disabled: false,
          to: { name: "module-petroshops-offers" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    enquiry() {
      return this.$store.getters["petroshops/enquiriesStore/get"];
    },
  },
  methods: {},
};
</script>
