<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>
            {{
              this.status != "New"
                ? "New Landlord - " + this.status
                : "Enquiries"
            }}
          </h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Enquiries"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom v-if="this.status == 'New'">
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.enquiryDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Enquiry</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Enquires</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="enquiries"
        class="mt-4"
        :items-per-page="-1"
        no-data-text="No Enquiries found"
      >
        <template v-slot:item.address="{ item }">
          {{ item.property_address_line1 }}
          {{ item.property_address_line2 }}
          {{ item.property_address_town }}
          {{ item.property_address_postcode }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                class="mr-2"
                :to="{
                  name: 'module-sbpm-enquiries-individual',
                  params: { enquiryId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.enquiryDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              item.agreement_type &&
              item.agreement_type != 'TBC' &&
              status == 'New'
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-3 green--text"
                v-on="on"
                class="mr-2"
                @click="openConvert(item)"
              >
                <v-icon x-small>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Convert</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <enquiry-dialog ref="enquiryDialog" />

    <v-dialog v-model="convertEnquiry.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Convert Enquiry</v-card-title>
        <v-card-text
          >Are you sure you want to convert this enquiry?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetConvert"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="convertEnquiry.loading"
            @click="saveConvert"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteEnquiry.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Enquiry</v-card-title>
        <v-card-text
          >Are you sure you want to archive this enquiry form?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteEnquiry.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EnquiryDialog from "./components/EnquiryDialog.vue";

export default {
  props: {
    status: {
      type: [String],
      default: null,
    },
  },
  components: {
    EnquiryDialog,
  },

  data() {
    return {
      deleteEnquiry: {
        dialog: false,
        enquiry: {},
        loading: false,
      },
      convertEnquiry: {
        dialog: false,
        enquiry: {},
        loading: false,
      },

      tableHeaders: [
        { text: "Address", value: "address" },
        { text: "Name", value: "landlord_full_name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Agreement Type", value: "agreement_type" },
        { text: "Assignee", value: "assigne_user.full_name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Enquiries",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    enquiries() {
      let enquiries = this.$store.getters["sbpm/enquiriesStore/all"];

      if (this.status !== null) {
        enquiries = enquiries.filter((c) => c.status == this.status);
      }

      if (this.searchTerm !== "") {
        enquiries = enquiries.filter((p) => {
          const landlord_full_name = p.landlord_full_name
            ? p.landlord_full_name.toLowerCase()
            : "";

          const property_address_line1 = p.property_address_line1
            ? p.property_address_line1.toLowerCase()
            : "";
          const property_address_line2 = p.property_address_line2
            ? p.property_address_line2.toLowerCase()
            : "";
          const property_address_town = p.property_address_town
            ? p.property_address_town.toLowerCase()
            : "";
          const property_address_region = p.property_address_region
            ? p.property_address_region.toLowerCase()
            : "";
          const property_address_postcode = p.property_address_postcode
            ? p.property_address_postcode.toLowerCase()
            : "";

          const searchTerm = this.searchTerm.toLowerCase();

          return (
            landlord_full_name.includes(searchTerm) ||
            property_address_line1.includes(searchTerm) ||
            property_address_line2.includes(searchTerm) ||
            property_address_town.includes(searchTerm) ||
            property_address_region.includes(searchTerm) ||
            property_address_postcode.includes(searchTerm)
          );
        });
      }

      return enquiries;
    },
  },

  methods: {
    openDelete(enquiry) {
      this.deleteEnquiry.enquiry = enquiry;
      this.deleteEnquiry.dialog = true;
    },

    resetDelete() {
      this.deleteEnquiry.dialog = false;
      this.deleteEnquiry.enquiry = {};
      this.deleteEnquiry.loading = false;
    },

    saveDelete() {
      this.deleteEnquiry.loading = true;

      this.$store
        .dispatch("sbpm/enquiriesStore/deleteEnquiry", {
          appId: this.$route.params.id,
          enquiryId: this.deleteEnquiry.enquiry.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteEnquiry.loading = false;
        });
    },

    openConvert(enquiry) {
      this.convertEnquiry.enquiry = enquiry;
      this.convertEnquiry.dialog = true;
    },

    resetConvert() {
      this.convertEnquiry.dialog = false;
      this.convertEnquiry.enquiry = {};
      this.convertEnquiry.loading = false;
    },

    saveConvert() {
      this.convertEnquiry.loading = true;

      this.$store
        .dispatch("sbpm/enquiriesStore/convertEnquiry", {
          appId: this.$route.params.id,
          enquiryId: this.convertEnquiry.enquiry.id,
        })
        .then(() => {
          this.resetConvert();
        })
        .catch(() => {
          this.convertEnquiry.loading = false;
        });
    },
  },
};
</script>
