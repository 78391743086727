<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-craigtoun-seasontickets' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Season Tickets</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>
            {{ ticket.identifier }} -
            {{ ticket.customer.full_name }}
          </h1>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="orange lighten-4 orange--text"
                class="mr-2"
                v-on="on"
                @click="$refs.ticketDialog.open(ticket, true)"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Renew</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.ticketDialog.open(ticket)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Ticket Holders</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.ticketHolderDialog.open()"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </template>
                <span>Add New Ticket Holder</span>
              </v-tooltip>
            </v-toolbar>

            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col
                  cols="4"
                  v-for="(holder, index) in ticket.holders"
                  :key="holder.id"
                >
                  <v-card outlined>
                    <v-list-item>
                      <v-list-item-avatar
                        :color="holder.is_guest ? 'grey' : 'accent'"
                      >
                        <img
                          v-if="holder.photo_url"
                          :src="holder.asset_urls.photo_url"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Holder #{{ index + 1 }}</v-list-item-subtitle
                        >
                        <v-list-item-title v-if="holder.is_guest === false">{{
                          holder.full_name
                        }}</v-list-item-title>
                        <v-list-item-title class="text--disabled" v-else
                          >Guest</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action v-if="index !== 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              color="blue"
                              v-on="on"
                              @click="$refs.ticketHolderDialog.open(holder)"
                            >
                              <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Holder</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Basic Details</v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Customer</th>
                  <td>
                    <router-link
                      :to="{
                        name: 'module-craigtoun-customers-individual',
                        params: { customerId: ticket.customer.id },
                      }"
                      >{{ ticket.customer.full_name }}</router-link
                    >
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <v-chip
                      v-if="
                        new Date().toISOString().substring(0, 10) >
                        ticket.valid_to
                      "
                      small
                      label
                      color="red"
                      text-color="white"
                      >Expired</v-chip
                    >
                    <v-chip
                      v-else
                      small
                      label
                      :color="getSeasonPassStatusColor(ticket.status)"
                      >{{ getSeasonPassStatusLabel(ticket.status) }}</v-chip
                    >
                  </td>
                </tr>
                <tr>
                  <th>Ticket Type</th>
                  <td>{{ ticket.ticket_type.title }}</td>
                </tr>
                <tr>
                  <th>Cost</th>
                  <td>£{{ ticket.cost }}</td>
                </tr>
                <tr>
                  <th>Valid From</th>
                  <td>{{ ticket.formatted_dates.valid_from }}</td>
                </tr>
                <tr>
                  <th>Valid To</th>
                  <td>{{ ticket.formatted_dates.valid_to }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ticket-dialog ref="ticketDialog" />
    <ticket-holder-dialog ref="ticketHolderDialog" />
  </div>
</template>

<script>
import TicketDialog from "./components/TicketDialog";
import TicketHolderDialog from "./components/TicketHolderDialog";
import { craigtoun } from "../../mixins";

export default {
  mixins: [craigtoun],

  props: {
    appColor: {
      type: String,
    },
  },

  components: {
    TicketDialog,
    TicketHolderDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Season Tickets",
          disabled: false,
          to: { name: "module-craigtoun-seasontickets" },
          exact: true,
        },
        {
          text: "Ticket",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    ticket() {
      return this.$store.getters["craigtoun/ticketsStore/get"];
    },

    types() {
      return this.$store.getters["craigtoun/ticketsStore/types"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.ticket.identifier,
      disabled: true,
    });
  },
};
</script>
