import brochureRoutes from "./brochures.routes";
import offerRoutes from "./offers.routes";
import productRoutes from "./products.routes";

export default [
  {
    path: "petroshops",
    component: () => import("@/modules/petroshops/views/Template"),
    children: [...productRoutes, ...offerRoutes, ...brochureRoutes],
  },
];
