import store from "@/stores/";

export default [
  {
    path: "products",
    component: () => import("@/modules/petroshops/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("users/loadModule", {
          appId: to.params.id,
          module: "users",
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-ps-products",
        component: () =>
          import("@/modules/petroshops/views/products/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("petroshops/productsStore/loadProducts", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-ps-products-archived",
        component: () => import("@/modules/petroshops/views/products/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("petroshops/productsStore/loadProducts", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":productId",
        name: "module-ps-products-individual",
        component: () =>
          import("@/modules/petroshops/views/products/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("petroshops/productsStore/loadProduct", {
              appId: to.params.id,
              productId: to.params.productId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
