import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  brochures: [],
  archived: [],
  brochure: {},
};

// getters
const getters = {
  all: (state) => state.brochures,
  archived: (state) => state.archived,
  get: (state) => state.brochure,
};

// actions
const actions = {
  loadBrochures({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/brochures`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBrochuresSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadBrochure({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/brochures/${
          data.brochureId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBrochureSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveBrochure({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/petroshops/brochures/${
          data.brochureId
        }`
      : `${getApiUrl(data.appId)}/module/petroshops/brochures`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBrochureSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteBrochure({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/brochures/${
          data.brochureId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteBrochureSuccess", {
            brochureId: data.brochureId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreBrochure({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/brochures/${
          data.brochureId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreBrochureSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadBrochuresSuccess(state, data) {
    state.brochures = data.brochures;
    state.archived = data.archived;
  },

  loadBrochureSuccess(state, data) {
    state.brochure = data.brochure;
  },

  saveBrochureSuccess(state, data) {
    if (data.isEditing) {
      const index = state.brochures.findIndex((p) => p.id === data.brochure.id);
      state.brochures.splice(index, 1, data.brochure);
    } else {
      state.brochures.push(data.brochure);
    }
  },

  restoreBrochureSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.brochureId);
    state.archived.splice(index, 1);
    state.brochures.push(data.brochure);
  },

  deleteBrochureSuccess(state, data) {
    const index = state.brochures.findIndex((c) => c.id === data.brochureId);
    state.brochures.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
