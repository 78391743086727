<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Communities</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Communities"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  class="mr-2"
                  color="accent"
                  v-on="on"
                  @click="$refs.communityDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Article</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed light v-on="on">
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived communities</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="communities"
        no-data-text="No communities found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                class="mr-2"
                v-on="on"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                class="mr-2"
                v-on="on"
                @click="$refs.communityDialog.open(item)"
                ><v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>

    <community-dialog ref="communityDialog" />

    <v-dialog v-model="deleteCommunity.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Community</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteCommunity.community.community_name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteCommunity.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommunityDialog from "./components/CommunityDialog.vue";

export default {
  components: { CommunityDialog },

  data() {
    return {
      searchTerm: "",
      deleteCommunity: {
        dialog: false,
        community: {},
        loading: false,
      },
      breadcrumbs: [
        {
          text: "Communities",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "community_name" },
        { text: "Domain", value: "domain" },
        { text: "Actions", value: "actions", align: "end" },
      ],
    };
  },

  methods: {
    openDelete(community) {
      this.deleteCommunity.community = community;
      this.deleteCommunity.dialog = true;
    },

    resetDelete() {
      this.deleteCommunity.dialog = false;
      this.deleteCommunity.community = {};
      this.deleteCommunity.loading = false;
    },

    saveDelete() {
      this.deleteCommunity.loading = true;

      this.$store
        .dispatch("community/communitiesStore/deleteCommunity", {
          appId: this.$route.params.id,
          communityId: this.deleteCommunity.community.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteCommunity.loading = false;
        });
    },
  },

  computed: {
    communities() {
      let communities = this.$store.getters["community/communitiesStore/all"];

      if (this.searchTerm !== "") {
        communities = communities.filter((a) => {
          const title = a.title.toLowerCase();
          const slug = a.slug.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return title.includes(searchTerm) || slug.inclues(searchTerm);
        });
      }

      return communities;
    },
  },
};
</script>
