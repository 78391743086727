import brochuresStore from "./brochures.store";
import offersStore from "./offers.store";
import productsStore from "./products.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  brochuresStore,
  offersStore,
  productsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
