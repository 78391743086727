import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  articles: [],
  archivedArticles: [],
  article: {},
  newsCategories: [],
  archivedNewsCategories: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadNewsModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/news`,
        method: "GET",
      })
        .then((resp) => {
          commit("moduleLoadNewSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/news/${data.articleId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadArticleSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveArticle({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/news/${data.articleId}`
      : `${getApiUrl(data.appId)}/module/news`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveArticleSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/news/${data.articleId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteArticleSuccess", { articleId: data.articleId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadNewsCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/news/categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadNewsCategoriesSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveNewsImage({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/news/${data.articleId}/images/${
          data.imageId
        }`
      : `${getApiUrl(data.appId)}/module/news/${data.articleId}/images`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNewsImageSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveNewsCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/news/categories/${data.categoryId}`
      : `${getApiUrl(data.appId)}/module/news/categories`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNewsCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteNewsCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/news/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteNewsCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  moduleLoadNewSuccess(state, data) {
    state.articles = data.articles;
    state.archivedArticles = data.archived;
  },
  loadArticleSuccess(state, data) {
    state.article = data.article;
  },
  saveArticleSuccess(state, data) {
    if (data.isEditing) {
      const index = state.articles.findIndex((a) => a.id === data.article.id);
      state.articles.splice(index, 1, data.article);
    } else {
      state.articles.push(data.article);
    }
  },
  deleteArticleSuccess(state, data) {
    const index = state.articles.findIndex((a) => a.id === data.articleId);
    state.archivedArticles.push(state.articles[index]);
    state.articles.splice(index, 1);
  },
  loadNewsCategoriesSuccess(state, data) {
    state.newsCategories = data.categories;
    state.archivedNewsCategories = data.archived;
  },
  saveNewsCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.newsCategories.findIndex(
        (a) => a.id === data.category.id
      );
      state.newsCategories.splice(index, 1, data.category);
    } else {
      state.newsCategories.push(data.category);
    }
  },
  saveNewsImageSuccess(state, data) {
    state.article.images.push(data.image);
  },
  deleteNewsCategorySuccess(state, data) {
    const index = state.newsCategories.findIndex(
      (c) => c.id === data.categoryId
    );
    state.archivedNewsCategories.push(state.newsCategories[index]);
    state.newsCategories.splice(index, 1);
  },
};

// modules
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
