<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="3" class="pr-2">
          <h1>Work Groups</h1>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Search Work Groups"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Filter by Type"
            :items="types"
            v-model="searchType"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-btn
            depressed
            color="accent"
            class="mr-2"
            @click="addWorkGroupForm.dialog = true"
          >
            <v-icon>mdi-view-grid-plus</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                light
                v-on="on"
                :to="{ name: 'module-workgroup-closed' }"
              >
                <v-icon left>mdi-archive</v-icon>View Closed Workgroups
              </v-btn>
            </template>
            <span>View Closed Workgroups</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="groups"
            :items-per-page="-1"
            show-expand
            no-data-text="No work groups found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'module-group-individual',
                  params: { groupId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>

            <template v-slot:item.customer="{ item }">
              <router-link
                v-if="item.customer"
                :to="{
                  name: 'customer-profile-basic',
                  params: { customerId: item.customer.id },
                }"
                >{{ item.customer.name }}</router-link
              >

              <div v-if="item.is_internal == 1" style="color: #cccccc">
                VITAL HIKE
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.workGroupForm.openEditWorkGroup(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip
                bottom
                v-if="
                  hasOpenWorChunk(item) && hasChildrenHasOpenWorkChunk(item)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="
                      archiveWorkGroupDialog = true;
                      archiveWorkGroupData = item;
                    "
                  >
                    <v-icon x-small>mdi-package-variant-closed-remove</v-icon>
                  </v-btn>
                </template>
                <span>Close Group</span>
              </v-tooltip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pt-4 pb-4">
                <v-data-table
                  :items-per-page="-1"
                  disable-pagination
                  hide-default-footer
                  hide-default-header
                  :headers="tableHeadersChild"
                  :items="item.children"
                  no-data-text="No work groups found"
                >
                  <template v-slot:item.name="{ item }">
                    <router-link
                      :to="{
                        name: 'module-group-individual',
                        params: { groupId: item.id },
                      }"
                      >{{ item.name }}</router-link
                    >
                  </template>

                  <template v-slot:item.customer="{ item }">
                    <router-link
                      v-if="item.customer"
                      :to="{
                        name: 'customer-profile-basic',
                        params: { customerId: item.customer.id },
                      }"
                      >{{ item.customer.name }}</router-link
                    >
                    <div v-if="item.is_internal == 1" style="color: #cccccc">
                      VITAL HIKE
                    </div>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          class="mr-2"
                          @click="$refs.workGroupForm.openEditWorkGroup(item)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="hasOpenWorChunk(item)">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="red lighten-4 red--text"
                          v-on="on"
                          @click="
                            archiveWorkGroupDialog = true;
                            archiveWorkGroupData = item;
                          "
                        >
                          <v-icon x-small
                            >mdi-package-variant-closed-remove</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Close Group</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template #item.data-table-expand="{ item, expand, isExpanded }">
              <td v-if="item.children.length > 0" class="text-start">
                <v-btn
                  icon
                  @click="expand(!isExpanded)"
                  class="v-data-table__expand-icon"
                  :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="addWorkGroupForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="addWorkGroup" method="post">
          <v-card-title>
            <span class="headline">Add New Work Group</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name *"
                  type="text"
                  v-model="addWorkGroupForm.name"
                  outlined
                  :hide-details="
                    !addWorkGroupForm.errors.hasOwnProperty('name')
                  "
                  :error="addWorkGroupForm.errors.hasOwnProperty('name')"
                  :error-messages="addWorkGroupForm.errors['name']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-switch
                  v-model="addWorkGroupForm.is_internal"
                  :error="addWorkGroupForm.errors.hasOwnProperty('is_internal')"
                  :error-messages="addWorkGroupForm.errors['is_internal']"
                  :hide-details="
                    !addWorkGroupForm.errors.hasOwnProperty('is_internal')
                  "
                  class="mt-1"
                  label="Is Internal?"
                ></v-switch>
              </v-col>
              <v-col cols="8">
                <v-select
                  v-if="addWorkGroupForm.is_internal == false"
                  label="Customer"
                  :items="filteredCustomers"
                  v-model="addWorkGroupForm.customer_id"
                  item-text="name"
                  item-value="id"
                  :return-object="false"
                  outlined
                  :hide-details="
                    !addWorkGroupForm.errors.hasOwnProperty('customer_id')
                  "
                  :error="addWorkGroupForm.errors.hasOwnProperty('customer_id')"
                  :error-messages="addWorkGroupForm.errors['customer_id']"
                >
                  <template v-slot:prepend-item>
                    <div class="pa-3">
                      <v-text-field
                        label="Search"
                        type="text"
                        v-model="searchTermSelect"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  v-model="addWorkGroupForm.description"
                  outlined
                  :hide-details="
                    !addWorkGroupForm.errors.hasOwnProperty('description')
                  "
                  background-color="white"
                  :error="addWorkGroupForm.errors.hasOwnProperty('description')"
                  :error-messages="addWorkGroupForm.errors['description']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Parent Workgroup"
                  v-model="addWorkGroupForm.parent_workgroup"
                  :items="filteredGroups"
                  item-text="name"
                  item-value="id"
                  outlined
                  :hide-details="
                    !addWorkGroupForm.errors.hasOwnProperty('parent_workgroup')
                  "
                  background-color="white"
                  :error="
                    addWorkGroupForm.errors.hasOwnProperty('parent_workgroup')
                  "
                  :error-messages="addWorkGroupForm.errors['parent_workgroup']"
                >
                  <template v-slot:prepend-item>
                    <div class="pa-3">
                      <v-text-field
                        label="Search"
                        type="text"
                        v-model="searchTermWorkGroupSelect"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Type* "
                  v-model="addWorkGroupForm.type"
                  :items="types"
                  outlined
                  :hide-details="
                    !addWorkGroupForm.errors.hasOwnProperty('type')
                  "
                  background-color="white"
                  :error="addWorkGroupForm.errors.hasOwnProperty('type')"
                  :error-messages="addWorkGroupForm.errors['type']"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="addWorkGroupForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addWorkGroupForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="archiveWorkGroupDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Close Work Group</v-card-title>
        <v-card-text>
          Are you sure you want to Close
          {{ archiveWorkGroupData ? archiveWorkGroupData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archiveWorkGroupDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveWorkGroupLoading"
            @click="closeWorkGroup(archiveWorkGroupData.id)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <WorkGroupForm
      ref="workGroupForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
    />
  </div>
</template>

<script>
import WorkGroupForm from "@/modules/workload/views/groups/components/WorkGroupForm";

export default {
  components: {
    WorkGroupForm,
  },
  data() {
    return {
      searchTerm: "",
      searchType: null,
      searchTermSelect: "",
      searchTermWorkGroupSelect: "",
      breadcrumbs: [
        {
          text: "Workload",
          disabled: false,
          exact: true,
          to: {
            name: "module-workload-all",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Work Groups",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "", value: "data-table-expand" },
        { text: "Name", value: "name" },
        { text: "Customer", value: "customer" },

        { text: "Actions", value: "actions", align: "right" },
      ],
      tableHeadersChild: [
        { text: "", value: "data-table-expand" },
        { text: "Name", value: "name" },
        { text: "Customer", value: "customer" },

        { text: "Actions", value: "actions", align: "right" },
      ],

      addWorkGroupForm: {
        dialog: false,
        name: null,
        type: null,
        parent_workgroup: null,
        description: null,
        customer_id: null,
        is_internal: false,
        loading: false,
        errors: {},
      },
      types: ["Contract", "Project", "Ad-hoc", "Internal"],
      archiveWorkGroupDialog: false,
      archiveWorkGroupLoading: false,
      archiveWorkGroupData: null,
    };
  },

  computed: {
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },

    groups() {
      let groups = this.$store.getters["workload/parentWorkGroup"];

      groups = groups.filter((c) => c.type == "Project");

      if (this.searchTerm !== "") {
        groups = groups.filter((c) => {
          const name = c.name.toLowerCase();

          const customer =
            c.customer !== null ? c.customer.name.toLowerCase() : "";
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            customer.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      if (this.searchType !== null) {
        groups = groups.filter((c) => {
          const type = c.type;
          return type.includes(this.searchType);
        });
      }

      return groups;
    },

    filteredGroups() {
      let groups = this.$store.state.workload["groups"];

      groups = groups.filter((c) => c.type == "Project");

      if (
        this.searchTermWorkGroupSelect !== "" ||
        this.searchTermWorkGroupSelect !== null
      ) {
        groups = groups.filter((g) => {
          const name = g.name.toLowerCase();
          return name.includes(this.searchTermWorkGroupSelect.toLowerCase());
        });
      }

      return groups;
    },

    filteredCustomers() {
      let customers = this.$store.state.customers["customers"];
      if (this.searchTermSelect !== "" || this.searchTermSelect !== null) {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.searchTermSelect.toLowerCase()) ||
            shortcode.includes(this.searchTermSelect.toLowerCase())
          );
        });
      }

      return customers;
    },
  },

  methods: {
    hasOpenWorChunk(work_group) {
      let open_chunks = work_group.work_chunks.filter(
        (c) => c.status == "Open"
      );

      if (open_chunks.length > 0) {
        return false;
      }

      return true;
    },

    hasChildrenHasOpenWorkChunk(work_group) {
      work_group.children.forEach((group) => {
        let groupNew = group.work_chunks.filter((c) => c.status == "Open");

        if (groupNew.length > 0) {
          return false;
        }
      });

      return true;
    },

    addWorkGroup: function () {
      const appId = this.$route.params.id;
      const name = this.addWorkGroupForm.name;
      const customer_id = this.addWorkGroupForm.customer_id;
      const type = this.addWorkGroupForm.type;
      const parent_workgroup = this.addWorkGroupForm.parent_workgroup;
      const description = this.addWorkGroupForm.description;
      const is_internal = this.addWorkGroupForm.is_internal;

      this.addWorkGroupForm.loading = true;

      this.$store
        .dispatch("workload/addGroup", {
          appId,
          name,
          customer_id,
          type,
          parent_workgroup,
          description,
          is_internal,
        })
        .then(() => {
          this.addWorkGroupForm = {
            dialog: false,
            name: null,
            type: null,
            parent_workgroup: null,
            customer_id: null,
            description: null,
            is_internal: false,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addWorkGroupForm.errors = err.response.data.errors;
          this.addWorkGroupForm.loading = false;
        });
    },
    closeWorkGroup: function (id) {
      const appId = this.$route.params.id;

      this.archiveWorkGroupLoading = true;

      const groupId = id;

      this.$store
        .dispatch("workload/closeWorkGroup", {
          appId,
          groupId,
        })
        .then(() => {
          this.archiveWorkGroupData = null;
          this.archiveWorkGroupLoading = false;
          this.archiveWorkGroupDialog = false;
        });
    },
  },
};
</script>
