<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Brochure</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="note-form">
          <v-text-field
            label="name"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-file-input
            v-model="fields.image"
            accept="image/png, image/jpeg"
            placeholder="Choose a image Image"
            prepend-icon="mdi-camera"
            label="Image *"
            outlined
            background-color="white"
            @change="imageHasChanged()"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>

          <v-file-input
            v-model="fields.document"
            accept="image/png, image/jpeg"
            placeholder="Choose a document Image"
            prepend-icon="mdi-camera"
            label="Document *"
            outlined
            background-color="white"
            @change="documentHasChanged()"
            :error="errors.hasOwnProperty('document')"
            :error-messages="errors['document']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="note-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        name: null,
        image: null,
        image_has_changed: false,
        document: null,
        document_has_changed: false,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {},

  methods: {
    open: function (brochure = null) {
      if (brochure !== null) {
        this.brochure = brochure;

        this.fields.name = brochure.name;

        this.fields.image = brochure.image
          ? new File([brochure.image], brochure.image)
          : null;

        this.fields.document = brochure.document
          ? new File([brochure.document], brochure.document)
          : null;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.brochureId = this.brochure.id;
      }

      this.$store
        .dispatch("petroshops/brochuresStore/saveBrochure", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        name: null,
        image: null,
        image_has_changed: false,
        document: null,
        document_has_changed: false,
      };
    },

    imageHasChanged() {
      this.fields.image_has_changed = true;
    },

    documentHasChanged() {
      this.fields.document_has_changed = true;
    },
  },
};
</script>
