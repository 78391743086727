<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Repairs</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Repairs"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-sbpm-communalrepairs' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Repairs
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="repairs"
        class="mt-4"
        no-data-text="No Archived Repairs found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                class="mr-2"
                :to="{
                  name: 'module-sbpm-communalrepairs-individual',
                  params: { repairId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon x-small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Repair</v-card-title>
        <v-card-text>Are you sure you want to restore Repair?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [],
      tableHeaders: [
        { text: "Property", value: "property.property_name" },
        { text: "Works", value: "short_description" },

        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        repair: {},
      },
    };
  },

  methods: {
    openRestore(repair) {
      this.restoreDialog.repair = repair;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.repair = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("sbpm/repairsStore/restore", {
          appId,
          repairId: this.restoreDialog.repair.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },

  computed: {
    repairs() {
      let repairs = this.$store.getters["sbpm/repairsStore/archived"];

      return repairs;
    },
  },
};
</script>
