<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="2">
          <h1>Dogs</h1>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Search Dogs"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            label="Filter by Status"
            :items="statuses"
            v-model="status"
            item-text="name"
            item-value="value"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            label="Filter by Gender"
            :items="genders"
            v-model="type"
            item-text="name"
            item-value="value"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            label="Filter by Location"
            :items="locations"
            v-model="searchLocation"
            item-text="name"
            item-value="value"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto" class="pr-12">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.dogForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Dog</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="dogs"
            no-data-text="No dogs"
            :items-per-page="-1"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>

            <template v-slot:item.dob="{ item }">
              {{ formatDate(item.dob) }}
            </template>

            <template v-slot:item.gender="{ item }">
              <div v-if="item.gender">
                {{ item.gender == "F" ? "Female" : "Male" }}
              </div>
            </template>

            <template v-slot:item.status="{ item }">
              <div v-if="item.status">{{ item.status | capitalize }}</div>
            </template>

            <template v-slot:item.location="{ item }">
              <div v-if="item.location">
                <div v-if="item.location == 'lhl' || item.location == 'fch'">
                  {{ item.location.toUpperCase() }}
                </div>
                <div v-else>
                  {{ item.location | capitalize }}
                </div>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                :to="{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>

              <v-btn
                bottom
                x-small
                depressed
                color="red lighten-4 red--text"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <DogForm ref="dogForm" />
    <v-dialog scrollable v-model="deleteDog.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Dog</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DogForm from "./components/DogForm";

export default {
  props: {
    type: {
      type: [String],
      default: null,
    },
    status: {
      type: [String],
      default: null,
    },
  },
  components: {
    DogForm,
  },

  data() {
    return {
      deleteDog: {
        dialog: false,
        dog: {},
        loading: false,
      },
      searchLocation: "lhl-fch",
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      statuses: [
        { name: "Breeding Dog", value: "breeding-dog" },
        { name: "Speyed/Neutered", value: "speyed" },
        { name: "Breeding Complete", value: "breeding-complete" },
        { name: "Puppy", value: "puppy" },
        { name: "External", value: "external" },
      ],

      locations: [
        { name: "LHL", value: "lhl" },
        { name: "FCH", value: "fch" },
        { name: "LHL/FCH", value: "lhl-fch" },
        { name: "Breeder Colleague", value: "breeder" },
        { name: "Customer", value: "customer" },
        { name: "External", value: "external" },
      ],

      genders: [
        { name: "Female", value: "F" },
        { name: "Male", value: "M" },
      ],

      tableHeaders: [
        { text: "Call Name", value: "name" },
        { text: "Kennel Name", value: "kennel_name" },
        { text: "Dob", value: "dob" },
        { text: "Colour", value: "colour.name" },
        { text: "Gender", value: "gender" },
        { text: "Size", value: "size.name" },
        { text: "Status", value: "status" },
        { text: "Location", value: "location" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    dogs() {
      let dogs = this.$store.getters["lhl/dogs/all"];

      if (this.searchTerm !== "") {
        dogs = dogs.filter((p) => {
          const name = p.name.toLowerCase();
          const kennelName = p.kennel_name ? p.kennel_name.toLowerCase() : "";
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || kennelName.includes(searchTerm);
        });
      }

      if (this.type !== null) {
        dogs = dogs.filter((c) => c.gender == this.type);
      }

      if (this.status !== null) {
        dogs = dogs.filter((c) => c.status == this.status);
      }

      if (this.searchLocation !== null) {
        if (this.searchLocation == "lhl-fch") {
          dogs = dogs.filter((c) => c.location == "lhl" || c.location == "fch");
        } else {
          dogs = dogs.filter((c) => c.location == this.searchLocation);
        }
      }

      return dogs;
    },
  },

  methods: {
    openDelete(dog) {
      this.deleteDog.dog = dog;
      this.deleteDog.dialog = true;
    },

    resetDelete() {
      this.deleteDog.dialog = false;
      this.deleteDog.dog = {};
      this.deleteDog.loading = false;
    },

    saveDelete() {
      this.deleteDog.loading = true;

      this.$store
        .dispatch("lhl/dogs/deleteDog", {
          appId: this.$route.params.id,
          dogId: this.deleteDog.dog.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteDog.loading = false;
        });
    },
  },
};
</script>
