import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  offers: [],
  archived: [],
  offer: {},
};

// getters
const getters = {
  all: (state) => state.offers,
  archived: (state) => state.archived,
  get: (state) => state.offer,
};

// actions
const actions = {
  loadOffers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/offers`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOffersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/offers/${
          data.offerId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOfferSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveOffer({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/petroshops/offers/${data.offerId}`
      : `${getApiUrl(data.appId)}/module/petroshops/offers`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOffersuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/offers/${
          data.offerId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteOfferSuccess", {
            offerId: data.offerId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/offers/${
          data.offerId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreOfferSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadOffersSuccess(state, data) {
    state.offers = data.offers;
    state.archived = data.archived;
  },

  loadOfferSuccess(state, data) {
    state.offer = data.offer;
  },

  saveOffersuccess(state, data) {
    if (data.isEditing) {
      const index = state.offers.findIndex((p) => p.id === data.offer.id);
      state.offers.splice(index, 1, data.offer);
    } else {
      state.offers.push(data.offer);
    }
  },

  restoreOfferSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.offerId);
    state.archived.splice(index, 1);
    state.offers.push(data.offer);
  },

  deleteOfferSuccess(state, data) {
    const index = state.offers.findIndex((c) => c.id === data.offerId);
    state.offers.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
