<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? (isRenewal ? "Renew" : "Edit") : "Add a new" }} Season
        Ticket
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="ticket-form">
          <v-autocomplete
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          ></v-autocomplete>
          <v-select
            label="Ticket Type *"
            v-model="fields.ticket_type_id"
            :items="types"
            item-value="id"
            item-text="title"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('ticket_type_id')"
            :error-messages="errors['ticket_type_id']"
          ></v-select>
          <v-text-field
            label="Valid From *"
            v-model="fields.valid_from"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('valid_from')"
            :error-messages="errors['valid_from']"
          ></v-text-field>
          <v-text-field
            label="Valid To *"
            v-model="fields.valid_to"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('valid_to')"
            :error-messages="errors['valid_to']"
          ></v-text-field>
          <v-text-field
            label="Cost *"
            type="number"
            min="0.00"
            step="1.00"
            v-model="fields.cost"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('cost')"
            :error-messages="errors['cost']"
          ></v-text-field>
          <v-select
            label="Status *"
            v-model="fields.status"
            :items="seasonPassStatuses"
            item-value="value"
            item-text="label"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>
          <v-text-field
            label="Identifier*"
            v-model="fields.identifier"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('identifier')"
            :error-messages="errors['identifier']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="ticket-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { craigtoun } from "../../../mixins";

export default {
  mixins: [craigtoun],

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      ticket: {},
      isRenewal: false,
      fields: {
        ticket_type_id: null,
        customer_id: null,
        valid_from: null,
        valid_to: null,
        cost: null,
        identifier: null,
        status: null,
      },
      errors: {},
    };
  },

  computed: {
    types() {
      return this.$store.getters["craigtoun/ticketsStore/types"];
    },

    customers() {
      return this.$store.getters["craigtoun/customersStore/all"];
    },
  },

  methods: {
    open(ticket = null, isRenewal = false) {
      if (ticket !== null) {
        this.ticket = ticket;
        this.fields.ticket_type_id = ticket.ticket_type_id;
        this.fields.customer_id = ticket.customer_id;
        this.fields.identifier = ticket.identifier;
        this.fields.cost = ticket.cost;
        this.fields.status = ticket.status;
        this.fields.valid_from = ticket.valid_from;
        this.fields.valid_to = ticket.valid_to;
        this.isEditing = true;

        if (isRenewal) {
          this.isRenewal = true;

          const validFrom = new Date(ticket.valid_to);
          const validTo = new Date(ticket.valid_to);

          // if (new Date().toISOString().substring(0, 10) > ticket.valid_to) {
          validFrom.setDate(new Date().getDate());
          validTo.setDate(new Date().getDate() + 366);
          // } else {
          //   validFrom.setDate(validTo.getDate());
          //   validTo.setDate(validTo.getDate() + 366);
          // }

          this.fields.valid_from = validFrom.toISOString().split("T")[0];
          this.fields.valid_to = validTo.toISOString().split("T")[0];
        } else {
          this.fields.valid_from = ticket.valid_from;
          this.fields.valid_to = ticket.valid_to;
        }
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.ticket = {};
      this.isRenewal = false;
      this.fields.ticket_type_id = null;
      this.fields.valid_from = null;
      this.fields.valid_to = null;
      this.fields.cost = null;
      this.fields.identifier = null;
      this.fields.status = null;
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.ticketId = this.ticket.id;
      }

      this.$store
        .dispatch("craigtoun/ticketsStore/saveTicket", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
