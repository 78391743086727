import Cookie from "js-cookie";
import VPLongLogo from "@/assets/long-logo.svg";
import axios from "axios";
import router from "../router";
import store from "@/stores/";

function hasAppAccess(id) {
  const apps = store.getters.apps;

  return apps.some((app) => app.id === id);
}

function hasPermission(permission, appId) {
  const roles = store.getters.roles;

  let hasPermission = false;
  for (let i = 0; i < roles.length; i++) {
    if (roles[i].app_id === parseInt(appId)) {
      let permissions = roles[i].permissions.map((p) => p.name);
      if (permissions.includes(permission)) {
        hasPermission = true;
        break;
      }
    }
  }

  return hasPermission;
}

function appHasModule(module, id) {
  const app = store.getters.apps.find((a) => a.id === id);

  return app.modules.some((m) => m.name === module);
}

function appHasIntegration(integration, appId) {
  const app = store.getters.apps.find((a) => a.id === parseInt(appId));
  const integrations = app.integrations.map((i) => i.toLowerCase());

  return integrations.includes(integration.toLowerCase());
}

function getSidebarItems(id) {
  const app = store.getters.apps.find((a) => a.id === id);

  let items = [
    {
      name: "dashboard",
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      to: { name: "app-dashboard" },
    },
  ];

  if (hasPermission("view-users", id) && false === appHasModule("team", id)) {
    items.push({
      name: "users",
      title: "Users",
      icon: "mdi-account-multiple",
      to: { name: "app-users" },
    });
  }

  app.modules.forEach((module) => {
    if (hasPermission("view-module-" + module.name, id)) {
      let submodules = [];
      module.submodules.forEach((sub) => {
        submodules.push({
          title: sub.display_name,
          to: { name: "module-" + module.name + "-" + sub.name },
        });
      });

      items.push({
        name: module.name,
        title: module.display_name,
        count: module.count,
        icon: module.icon,
        to: { name: "module-" + module.name },
        submodules,
        hasSubmodules: submodules.length > 0,
      });
    }
  });

  return items;
}

function getAccountSidebarItems() {
  let items = [
    {
      name: "general",
      title: "General",
      icon: "mdi-account",
      to: { name: "account-settings" },
    },
    {
      name: "change-password",
      title: "Change Password",
      icon: "mdi-lock-open",
      to: { name: "account-change-password" },
    },
  ];

  return items;
}

function getAppName(id) {
  const app = store.getters.apps.find((a) => a.id === id);

  return app.name;
}

function getAppColor(id) {
  const app = store.getters.apps.find((a) => a.id === id);

  return app.color ? app.color : "accent";
}

function getAppLongLogo(id = null) {
  if (id === null) {
    return VPLongLogo;
  }

  const app = store.getters.apps.find((a) => a.id === id);

  return app.long_logo
    ? window.location.origin + "/img/" + app.long_logo
    : VPLongLogo;
}

function getIndividualUser(id) {
  return store.state.users["users"].find((u) => u.id === id);
}

function getAppWidgets(id) {
  const app = store.getters.apps.find((a) => a.id === id);

  let widgets = [];

  app.modules.forEach((m) => {
    if (m.widgets.length > 0) {
      m.widgets.forEach((w) => widgets.push(w));
    }
  });

  return widgets;
}

function processIntegration(to) {
  switch (to.name) {
    case "xero-callback":
      processXeroIntegration(to);
  }
}

function processXeroIntegration(to) {
  const appId = localStorage.getItem("xeroAppId");
  const xeroState = localStorage.getItem("xeroState");

  if (typeof to.query.code === "undefined") {
    router.push({ name: "index" });
  } else if (
    typeof to.query.state === "undefined" ||
    to.query.state !== xeroState
  ) {
    router.push({ name: "index" });
  } else {
    store
      .dispatch("pipeline/xeroCallback", {
        appId,
        code: to.query.code,
      })
      .then(() => {
        localStorage.removeItem("xeroAppId");
        localStorage.removeItem("xeroState");

        router.push({ path: `/apps/${appId}/pipeline` });
      })
      .catch(() => {
        router.push({ name: "index" });
      });
  }
}

function getDate(date, divider = "/", swapped = false, monthOnly = false) {
  const d = new Date(date);
  const m = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const days = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  if (swapped) {
    return monthOnly
      ? d.getFullYear() + divider + m[d.getMonth()]
      : d.getFullYear() +
          divider +
          m[d.getMonth()] +
          divider +
          days[d.getDate() - 1];
  }

  return monthOnly
    ? m[d.getMonth()] + divider + d.getFullYear()
    : days[d.getDate() - 1] +
        divider +
        m[d.getMonth()] +
        divider +
        d.getFullYear();
}

function setTitle(appName = null, title = null) {
  let documentTitle = "";

  if (title !== null) {
    documentTitle = documentTitle.concat(title, " - ");
  }

  if (store.getters.brand.name !== "" && store.getters.brand.name !== null) {
    documentTitle = documentTitle.concat(store.getters.brand.name, " - ");
  } else if (appName !== null && appName !== "") {
    documentTitle = documentTitle.concat(appName, " - ");
  }

  const environment = process.env.VUE_APP_STAGE;
  if (environment !== "production") {
    documentTitle = documentTitle.concat(environment.toUpperCase(), " - ");
  }

  documentTitle = documentTitle.concat("Viewpoint by Vital Hike");

  document.title = documentTitle;
}

function getAvatar(avatarUrl) {
  return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${avatarUrl}`;
}

function getApiUrl(appId) {
  return `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${appId}`;
}

function getCookie() {
  let token = Cookie.get("XSRF-TOKEN");

  if (token) {
    return new Promise((resolve) => {
      resolve(token);
    });
  }

  return axios.get("/csrf-cookie");
}

export {
  hasAppAccess,
  getSidebarItems,
  getAccountSidebarItems,
  hasPermission,
  getAppName,
  getAppColor,
  getAppLongLogo,
  appHasModule,
  appHasIntegration,
  getAppWidgets,
  processIntegration,
  getDate,
  setTitle,
  getIndividualUser,
  getAvatar,
  getApiUrl,
  getCookie,
};
