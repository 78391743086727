import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  products: [],
  product: {},
};

// getters
const getters = {
  all: (state) => state.products,
  get: (state) => state.products,
};

// actions
const actions = {
  loadProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/products`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/products/${
          data.productId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/petroshops/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/petroshops/products`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/petroshops/products/${
          data.productId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", {
            productId: data.productId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadProductsSuccess(state, data) {
    state.products = data.products;
  },

  loadProduct(state, data) {
    state.product = data.product;
  },

  saveProductSuccess(state, data) {
    if (data.isEditing) {
      const index = state.products.findIndex((p) => p.id === data.products.id);
      state.products.splice(index, 1, data.product);
    } else {
      state.products.push(data.product);
    }
  },

  deleteProductsSuccess(state, data) {
    const index = state.products.findIndex((c) => c.id === data.productId);
    state.products.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
