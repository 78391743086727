<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Check Out</h1>
        </v-col>

        <v-col class="pl-12">
          <v-text-field
            label="Search CheckOut"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.checkoutDialog.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Checkout</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-sbpm-checkout-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Check Out</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="grid-row">
            <v-data-table
              :headers="tableHeaders"
              :items="checkouts"
              no-data-text="No check out"
              :items-per-page="-1"
            >
              <template v-slot:item.due_date="{ item }">
                {{ formatDate(item.due_date) }}
              </template>

              <template v-slot:item.assignee="{ item }">
                <div v-if="item.assigne_user">
                  {{ item.assigne_user.full_name }}
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="green lighten-4 green--text"
                  :to="{
                    name: 'module-sbpm-checkout-individual',
                    params: { checkoutId: item.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>

                <!-- <v-btn
                  x-small
                  depressed
                  class="mr-2"
                  color="blue lighten-4 blue--text"
                  @click="$refs.details.openForm(item)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn> -->

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item)"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>

            <!-- <v-simple-table>
              <thead>
                <tr>
                  <th
                    v-for="header in tableHeaders"
                    :key="header.text"
                    :class="header.align === 'right' ? 'text-right' : ''"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(checkout, index) in checkout" :key="index">
                  <td @click="$refs.details.openForm(checkout, searchType)">
                    {{checkout.title }}
                  </td>
                  <td>
                    <div v-if="checkout.assigne_user">
                      {{checkout.assigne_user.full_name }}
                    </div>
                  </td>
                  <td>
                    <div>{{ formatDate(checkout.due_date) }}</div>
                  </td>
                  <td class="text-right"></td>
                </tr>
              </tbody>
            </v-simple-table> -->
          </div>
        </v-col>
        <!-- <v-col> <Details ref="details" /></v-col> -->
      </v-row>
    </v-container>
    <checkoutDialog
      ref="checkoutDialog"
      :fromDashboard="true"
      :onlyManaged="true"
    />

    <v-dialog v-model="deleteDialog.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Check Out</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Details from "./components/Details.vue";
import CheckoutDialog from "../managed-properties/components/CheckOutDialog.vue";

export default {
  components: {
    CheckoutDialog,
    // Details,
  },

  data() {
    return {
      dialog: false,
      deleteDialog: {
        dialog: false,
        checkout: {},
        loading: false,
      },

      searchTerm: "",
      breadcrumbs: [
        {
          text: "Check Out",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "Property", value: "property.property_name" },
        { text: "Assignee", value: "assignee" },
        { text: "Due Date", value: "due_date" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    checkouts() {
      let checkout = this.$store.getters["sbpm/checkoutStore/all"];

      if (this.searchTerm !== "") {
        checkout = checkout.filter((p) => {
          const title = p.property.property_name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return title.includes(searchTerm);
        });
      }

      return checkout;
    },
  },

  methods: {
    openDelete(checkout) {
      this.deleteDialog.checkout = checkout;
      this.deleteDialog.dialog = true;
    },

    resetDelete() {
      this.deleteDialog.dialog = false;
      this.deleteDialog.checkout = {};
      this.deleteDialog.loading = false;
    },

    saveDelete() {
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("sbpm/checkoutStore/deleteCheckOut", {
          appId: this.$route.params.id,
          checkoutId: this.deleteDialog.checkout.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteDialog.loading = false;
        });
    },
  },
};
</script>
<style>
.sidebar {
  width: 100%;
  min-height: 610px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
</style>
