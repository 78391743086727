<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-sbpm-managedproperties' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Properties</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="pr-12">
          <h1>{{ property.property_name }}</h1>
        </v-col>

        <!-- <v-col cols="auto" class="pr-12">
          <v-btn
            small
            depressed
            class="mr-2"
            color="green green--text lighten-4"
            @click="$refs.checkOutDialog.openForm()"
            >Add Check Out</v-btn
          >

          <v-btn
            small
            depressed
            class="mr-2"
            color="green green--text lighten-4"
            @click="$refs.moveOutDialog.openForm()"
            >Add Move Out</v-btn
          >
        </v-col> -->
      </v-row>

      <v-row class="pr-12">
        <v-col cols="4">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Property Address</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <td>Address</td>
                  <td>{{ property.property_name }}</td>
                </tr>

                <tr>
                  <td>Status</td>
                  <td>{{ property.status }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Checkouts</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.checkOutDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="checkoutTableHeaders"
              :items="property.checkouts"
              no-data-text="No Checkouts"
            >
              <template v-slot:item.due_date="{ item }">
                {{ formatDate(item.due_date) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      :to="{
                        name: 'module-sbpm-checkout-individual',
                        params: { checkoutId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Communal Repairs</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.checkOutDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New</span>
              </v-tooltip> -->
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="repairTableHeaders"
              :items="property.repairs"
              no-data-text="No Repairs"
            >
              <template v-slot:item.due_date="{ item }">
                {{ formatDate(item.due_date) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      :to="{
                        name: 'module-sbpm-communalrepairs-individual',
                        params: { repairId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Landlord Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 gren--text"
                    class="mr-2"
                    :disabled="
                      !property.landlord_email && !property.landlord_email_2
                    "
                    v-on="on"
                    @click="sendToLandlordPrompt()"
                  >
                    <v-icon small>mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Send 360 Viewing</span>
              </v-tooltip> -->
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.landlorsDialog.openForm(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip> -->
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{{ property.landlord_name }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ property.landlord_email }}</td>
                </tr>
                <tr>
                  <td>Email 2</td>
                  <td>{{ property.landlord_email_2 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Move Outs</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.moveOutDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="checkoutTableHeaders"
              :items="property.moveouts"
              no-data-text="No moveouts"
            >
              <template v-slot:item.due_date="{ item }">
                {{ formatDate(item.due_date) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      :to="{
                        name: 'module-sbpm-moveout-individual',
                        params: { moveoutId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Notes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(property)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="property.notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6" v-if="currentUserRole.name == 'VH Superadmin'">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Audit</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="property.audits"
              no-data-text="No Audit"
              :items-per-page="-1"
            >
              <template v-slot:item.audit_date="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <NoteDialog ref="noteDialog" type="property" />
      <LandlorsDialog ref="landlorsDialog" />
      <move-out-dialog
        ref="moveOutDialog"
        :property="property.id"
        :fromProperty="true"
      />
      <check-out-dialog
        ref="checkOutDialog"
        :property="property.id"
        :fromProperty="true"
      />
    </v-container>
  </div>
</template>

<script>
import MoveOutDialog from "./components/MoveOutDialog.vue";
import CheckOutDialog from "./components/CheckOutDialog.vue";
import NoteDialog from "../notes/NoteDialog.vue";
import LandlorsDialog from "../properties/components/LandlorsDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },

  components: {
    MoveOutDialog,
    CheckOutDialog,
    NoteDialog,
    LandlorsDialog,
  },

  data() {
    return {
      pdfLoading: false,
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      fields: {
        attending_viewing: false,
      },
      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],

      checkoutTableHeaders: [
        { text: "Date/Time", value: "due_date" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      repairTableHeaders: [
        { text: "Works", value: "short_description" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-sbpm-properties" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    property() {
      return this.$store.getters["sbpm/propertiesStore/get"];
    },
  },
  methods: {
    openDelete(form) {
      this.deleteForm.form = form;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.form = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteForm", {
          appId: this.$route.params.id,
          formId: this.deleteForm.form.application_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },
  },
};
</script>
