var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","depressed":"","to":{ name: 'module-craigtoun-memberships' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v("Back to Memberships")],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12"},[_c('h1',[_vm._v(" "+_vm._s(_vm.membership.customer.full_name)+" ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"blue lighten-4 blue--text"},on:{"click":function($event){return _vm.$refs.membershipDialog.open(_vm.membership)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Edit")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","dense":"","color":_vm.appColor}},[_c('v-toolbar-title',[_vm._v("Basic Details")]),_c('v-spacer')],1),_c('v-divider'),_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("Customer")]),_c('td',[_c('router-link',{attrs:{"to":{
                      name: 'module-craigtoun-customers-individual',
                      params: { customerId: _vm.membership.customer.id },
                    }}},[_vm._v(_vm._s(_vm.membership.customer.full_name))])],1)]),_c('tr',[_c('th',[_vm._v("Status")]),_c('td',[(
                      new Date().toISOString().substring(0, 10) >
                      _vm.membership.valid_to
                    )?_c('v-chip',{attrs:{"small":"","label":"","color":"red","text-color":"white"}},[_vm._v("Expired")]):_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(_vm.membership.status))])],1)]),_c('tr',[_c('th',[_vm._v("Cost")]),_c('td',[_vm._v("£"+_vm._s(_vm.membership.cost))])]),_c('tr',[_c('th',[_vm._v("Valid From")]),_c('td',[_vm._v(_vm._s(_vm.membership.formatted_dates.valid_from))])]),(_vm.membership.valid_to)?_c('tr',[_c('th',[_vm._v("Valid To")]),_c('td',[_vm._v(_vm._s(_vm.membership.formatted_dates.valid_to))])]):_vm._e()])])],1)],1)],1)],1),_c('membership-dialog',{ref:"membershipDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }