var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mb-5 mt-5",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("PHOTO ID")]),_c('th',[_vm._v(" Document Status: "),_c('v-icon',{attrs:{"color":_vm.iconColor(_vm.form.photo_id_status)}},[_vm._v(" "+_vm._s(_vm.imageStatus(_vm.form.photo_id_status))+" ")])],1)]),_vm._l((_vm.form.photo_id_status_file),function(photo){return _c('tr',{key:photo.id},[_c('td',[_vm._v(" "+_vm._s(photo.file)+" ")]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","href":photo.asset_urls.file,"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":""},on:{"click":function($event){return _vm.openDelete(photo)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)],1)])}),(_vm.form.photo_id_status_file.length > 0)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"color":"green lighten-4 green--text mr-2"},on:{"click":function($event){return _vm.$refs.verifiedUpload.changeStatusPrompt(
                          'Verified',
                          'photo_id_status'
                        )}}},[_vm._v("VERIFY")]),_c('v-btn',{attrs:{"color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.StatusUploadDialog.changeStatusPrompt(
                          'Returned',
                          'photo_id_status'
                        )}}},[_vm._v("RETURN")])],1)]):_vm._e()],2)])],1)],1)],1),_c('v-col',{staticClass:"mb-5 mt-5",attrs:{"cols":"6"}},[(_vm.form.tenant_type == 'student')?_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("STUDENT ID")]),_c('th',[_vm._v(" Document Status: "),_c('v-icon',{attrs:{"color":_vm.iconColor(_vm.form.student_id_status)}},[_vm._v(" "+_vm._s(_vm.imageStatus(_vm.form.student_id_status))+" ")])],1)]),_vm._l((_vm.form.student_id_status_file),function(photo){return _c('tr',{key:photo.id},[_c('td',[_vm._v(_vm._s(photo.file))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","href":photo.asset_urls.file,"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":""},on:{"click":function($event){return _vm.openDelete(photo)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)],1)])}),(_vm.form.student_id_status_file.length > 0)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"color":"green lighten-4 green--text mr-2"},on:{"click":function($event){return _vm.$refs.verifiedUpload.changeStatusPrompt(
                          'Verified',
                          'student_id_status'
                        )}}},[_vm._v("VERIFY")]),_c('v-btn',{attrs:{"color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.StatusUploadDialog.changeStatusPrompt(
                          'Returned',
                          'student_id_status'
                        )}}},[_vm._v("RETURN")])],1)]):_vm._e()],2)])],1)],1):_vm._e(),(_vm.form.tenant_type == 'professional')?_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("3 MONTHS BANK STATEMENTS")]),_c('th',[_vm._v(" Document Status: "),_c('v-icon',{attrs:{"color":_vm.iconColor(_vm.form.bank_statements_status)}},[_vm._v(" "+_vm._s(_vm.imageStatus(_vm.form.bank_statements_status))+" ")])],1)]),_vm._l((_vm.form.bank_statements_status_file),function(photo){return _c('tr',{key:photo.id},[_c('td',[_vm._v(_vm._s(photo.file))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","href":photo.asset_urls.file,"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":""},on:{"click":function($event){return _vm.openDelete(photo)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)],1)])}),(_vm.form.bank_statements_status_file.length > 0)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"color":"green lighten-4 green--text mr-2"},on:{"click":function($event){return _vm.$refs.verifiedUpload.changeStatusPrompt(
                          'Verified',
                          'bank_statements_status'
                        )}}},[_vm._v("VERIFY")]),_c('v-btn',{attrs:{"color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.StatusUploadDialog.changeStatusPrompt(
                          'Returned',
                          'bank_statements_status'
                        )}}},[_vm._v("RETURN")])],1)]):_vm._e()],2)])],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"6"}},[(_vm.form.tenant_type == 'student')?_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("UNIVERSITY ACCEPTANCE")]),_c('th',[_vm._v(" Document Status: "),_c('v-icon',{attrs:{"color":_vm.iconColor(_vm.form.uni_acceptance_status)}},[_vm._v(" "+_vm._s(_vm.imageStatus(_vm.form.uni_acceptance_status))+" ")])],1)]),_vm._l((_vm.form.uni_acceptance_status_file),function(photo){return _c('tr',{key:photo.id},[_c('td',[_vm._v(_vm._s(photo.file))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","href":photo.asset_urls.file,"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":""},on:{"click":function($event){return _vm.openDelete(photo)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)],1)])}),(_vm.form.uni_acceptance_status_file.length > 0)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"color":"green lighten-4 green--text mr-2"},on:{"click":function($event){return _vm.$refs.verifiedUpload.changeStatusPrompt(
                          'Verified',
                          'uni_acceptance_status'
                        )}}},[_vm._v("VERIFY")]),_c('v-btn',{attrs:{"color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.StatusUploadDialog.changeStatusPrompt(
                          'Returned',
                          'uni_acceptance_status'
                        )}}},[_vm._v("RETURN")])],1)]):_vm._e()],2)])],1)],1):_vm._e(),(_vm.form.tenant_type == 'professional')?_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("3 MONTHS PAYSLIPS & EMPLOYMENT")]),_c('th',[_vm._v(" Document Status: "),_c('v-icon',{attrs:{"color":_vm.iconColor(_vm.form.payslips_status)}},[_vm._v(" "+_vm._s(_vm.imageStatus(_vm.form.payslips_status))+" ")])],1)]),_vm._l((_vm.form.payslips_status_file),function(photo){return _c('tr',{key:photo.id},[_c('td',[_vm._v(_vm._s(photo.file))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","href":photo.asset_urls.file,"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":""},on:{"click":function($event){return _vm.openDelete(photo)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)],1)])}),(_vm.form.payslips_status_file.length > 0)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"color":"green lighten-4 green--text mr-2"},on:{"click":function($event){return _vm.$refs.verifiedUpload.changeStatusPrompt(
                          'Verified',
                          'payslips_status'
                        )}}},[_vm._v("VERIFY")]),_c('v-btn',{attrs:{"color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.StatusUploadDialog.changeStatusPrompt(
                          'Returned',
                          'payslips_status'
                        )}}},[_vm._v("RETURN")])],1)]):_vm._e()],2)])],1)],1):_vm._e()],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("PREVIOUS / CURRENT LANDLORD REF")]),_c('th',[_vm._v(" Document Status: "),_c('v-icon',{attrs:{"color":_vm.iconColor(_vm.form.landlord_ref_status)}},[_vm._v(" "+_vm._s(_vm.imageStatus(_vm.form.landlord_ref_status))+" ")])],1)]),_vm._l((_vm.form.landlord_ref_status_file),function(photo){return _c('tr',{key:photo.id},[_c('td',[_vm._v(_vm._s(photo.file))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","href":photo.asset_urls.file,"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":""},on:{"click":function($event){return _vm.openDelete(photo)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)],1)])}),(_vm.form.landlord_ref_status_file.length > 0)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"color":"green lighten-4 green--text mr-2"},on:{"click":function($event){return _vm.$refs.verifiedUpload.changeStatusPrompt(
                          'Verified',
                          'landlord_ref_status'
                        )}}},[_vm._v("VERIFY")]),_c('v-btn',{attrs:{"color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.StatusUploadDialog.changeStatusPrompt(
                          'Returned',
                          'landlord_ref_status'
                        )}}},[_vm._v("RETURN")])],1)]):_vm._e()],2)])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog.open),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "open", $$v)},expression:"deleteDialog.open"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete File")]),_c('v-card-text',[_vm._v("Are you sure you want to delete?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDelete}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteDialog.loading},on:{"click":_vm.saveDelete}},[_vm._v(" Yes ")])],1)],1)],1),_c('status-upload-dialog',{ref:"StatusUploadDialog"}),_c('verified-upload',{ref:"verifiedUpload"}),_c('upload-dialog',{ref:"uploadDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }