<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col>
          <h1>{{ this.welcomeMessage }} 👋</h1>
        </v-col>
      </v-row>
      <DashboardWidgets :appId="appId" />
    </v-container>
  </div>
</template>

<script>
import * as Utils from "@/utils/index.js";
import DashboardWidgets from "@/views/widgets/DashboardWidgets";

export default {
  components: {
    DashboardWidgets,
  },

  data() {
    return {
      welcomeMessage: this.getWelcomeMessage(),
    };
  },

  computed: {
    appId() {
      return parseInt(this.$route.params.id);
    },
  },

  methods: {
    getWelcomeMessage: function () {
      const today = new Date();
      const firstName = this.$store.getters.userFirstName;

      if (today.getHours() < 12) {
        return "Good Morning, " + firstName;
      } else if (today.getHours() < 18) {
        return "Good Afternoon, " + firstName;
      } else {
        return "Good Evening, " + firstName;
      }
    },

    appHasModule: function (module) {
      return Utils.appHasModule(module, parseInt(this.$route.params.id));
    },
  },
};
</script>
