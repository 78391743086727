<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Move Out</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="moveout-form">
          <v-autocomplete
            v-if="fromDashboard"
            label="Property"
            v-model="fields.property_id"
            :items="properties"
            item-value="id"
            item-text="property_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_id')"
            :error-messages="errors['property_id']"
          ></v-autocomplete>

          <v-select
            v-if="fromDashboard"
            v-model="fields.assignee"
            label="Assignee"
            :items="users"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('assignee')"
            :error-messages="errors['assignee']"
          ></v-select>

          <v-text-field
            label="Due Date"
            v-model="fields.due_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('due_date')"
            :error-messages="errors['due_date']"
          ></v-text-field>

          <v-textarea
            label="Description"
            v-model="fields.description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="moveout-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    fromDashboard: Boolean,
    onlyManaged: Boolean,
    fromProperty: Boolean,
  },
  data() {
    return {
      fields: {
        due_date: null,
        description: null,
        property_id: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    users() {
      return this.$store.state.users["users"];
    },

    properties() {
      let properties = this.$store.getters["sbpm/propertiesStore/all"];

      if (this.onlyManaged) {
        properties = properties.filter((c) => c.status == "Managing");
      }

      return properties;
    },
  },

  methods: {
    openForm: function (model = null) {
      if (model !== null) {
        this.model = model;
        this.fields.property_id = model.property_id ? model.property.id : null;
        this.fields.assignee = model.assignee ? model.assigne_user.id : null;
        this.fields.due_date = model.due_date;
        this.fields.description = model.description;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.fromDashboard) {
        payload.propertyId = this.fields.property_id;
      } else {
        payload.propertyId = this.$route.params.propertyId;
        this.fields.property_id = this.$route.params.propertyId;
      }

      if (this.fromProperty) {
        this.dispatch = "sbpm/propertiesStore/saveProperty";
        payload.propertyId = this.$route.params.propertyId;
        payload.isEditing = true;
      } else {
        this.dispatch = "sbpm/moveoutStore/saveMoveout";
      }

      payload.fields.form_title = "move-out";

      if (this.isEditing) {
        payload.moveoutId = this.$route.params.moveoutId;
      }

      this.$store
        .dispatch(this.dispatch, payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        due_date: null,
        description: null,
      };
    },
  },
};
</script>
