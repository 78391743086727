<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>{{ isEditing ? "Update an" : "Create a new" }} Article</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Articles
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mt-2 mb-6"></v-divider>

      <v-row>
        <v-col cols="8">
          <v-text-field
            label="Title *"
            v-model="fields.title"
            outlined
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            outlined
            dense
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <v-select
            label="Categories *"
            :items="categories"
            v-model="fields.categories"
            item-value="id"
            item-text="title"
            outlined
            multiple
          ></v-select>
          <v-textarea
            label="Short Description *"
            v-model="fields.short_description"
            rows="3"
            outlined
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
            v-if="fields.template_type === 'wysiwyg'"
          ></v-textarea>
          <tiptap v-model="fields.content" class="mb-8" />
          <v-textarea
            label="Short Description *"
            v-model="fields.short_description"
            rows="3"
            outlined
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
          ></v-textarea>
          <v-switch
            label="Should this Article be Featured?"
            v-model="fields.is_featured"
            inset
            :error="errors.hasOwnProperty('is_featured')"
            :error-messages="errors['is_featured']"
          ></v-switch>
          <v-file-input
            label="Main Image"
            v-model="fields.images.main.file"
            accept="image/png, image/jpeg"
            placeholder="Choose a Main Image"
            outlined
            @change="mainImageHasChanged()"
            :error="errors.hasOwnProperty('main_image')"
            :error-messages="errors['main_image']"
          ></v-file-input>
          <v-file-input
            label="Thumbnail Image"
            v-model="fields.images.thumbnail.file"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            outlined
            @change="thumbnailHasChanged()"
            :error="errors.hasOwnProperty('thumbnail')"
            :error-messages="errors['thumbnail']"
          ></v-file-input>
          <v-file-input
            label="Featured Thumbnail Image"
            v-model="fields.images.featured_thumbnail.file"
            accept="image/png, image/jpeg"
            placeholder="Choose a Featured Thumbnail Image"
            outlined
            @change="featuredThumbnailHasChanged()"
            :error="errors.hasOwnProperty('featured_thumbnail')"
            :error-messages="errors['featured_thumbnail']"
            v-if="fields.is_featured"
          ></v-file-input>
          <v-btn depressed large color="accent" :loading="loading" @click="save"
            ><v-icon left>mdi-content-save</v-icon> Save Article</v-btn
          >
        </v-col>
        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Images</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.uploadDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add File</span>
              </v-tooltip>
            </v-toolbar>

            <v-data-table
              :headers="tableHeaders"
              :items="article.images"
              hide-default-footer
              class="mt-4"
              :items-per-page="-1"
              no-data-text="No Images found"
            >
              <template v-slot:item.photo="{ item }">
                <v-img
                  :src="item.asset_urls.file"
                  width="100"
                  @click.prevent="downloadItem(item.asset_urls.file)"
                >
                </v-img>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <upload-dialog ref="uploadDialog" />
    </v-container>
  </div>
</template>

<script>
import UploadDialog from "./components/UploadDialog.vue";
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
    UploadDialog,
  },

  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    parentRouteName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tableHeaders: [
        { text: "Photo", value: "photo" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      fields: {
        title: null,
        slug: null,
        status: "draft",
        categories: [],
        short_description: null,
        content: null,
        is_featured: false,
        images: {
          thumbnail: {
            file: null,
            has_changed: false,
          },
          featured_thumbnail: {
            file: null,
            has_changed: false,
          },
          main: {
            file: null,
            has_changed: false,
          },
        },
      },
      loading: false,
      errors: {},
    };
  },

  beforeMount() {
    if (this.isEditing) {
      this.fields.title = this.article.title;
      this.fields.slug = this.article.slug;
      this.fields.status = this.article.status;
      this.fields.short_description = this.article.short_description;
      this.fields.content = this.article.content;
      this.fields.is_featured = this.article.is_featured;

      this.fields.categories = this.article.categories.map((c) => c.id);

      this.fields.images.thumbnail.file = this.article.thumbnail_image
        ? new File(
            [this.article.asset_urls.thumbnail_image],
            this.article.thumbnail_image
          )
        : null;
      this.fields.images.main.file = this.article.main_image
        ? new File(
            [this.article.asset_urls.main_image],
            this.article.main_image
          )
        : null;
      this.fields.images.featured_thumbnail.file = this.article
        .featured_thumbnail_image
        ? new File(
            [this.article.asset_urls.featured_thumbnail_image],
            this.article.featured_thumbnail_image
          )
        : null;
    }
  },

  computed: {
    article() {
      return this.$store.state.news["article"];
    },

    categories() {
      return this.$store.state.news["newsCategories"];
    },

    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.title;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    methods: {
      downloadItem(image) {
        const link = document.createElement("a");
        link.href = image;
        link.target = "_blank";

        // Simulate a click on the element <a>
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
    save() {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      let formData = new FormData();
      formData.append("title", this.fields.title);
      formData.append("slug", this.fields.slug);
      formData.append("status", this.fields.status);
      formData.append("content", this.fields.content);
      formData.append("short_description", this.fields.short_description);
      formData.append("is_featured", this.fields.is_featured ? 1 : 0);

      for (let i = 0; i < this.fields.categories.length; i++) {
        formData.append("categories[]", this.fields.categories[i]);
      }

      formData.append(
        "thumbnail_has_changed",
        this.fields.images.thumbnail.has_changed ? 1 : 0
      );
      if (this.fields.images.thumbnail.has_changed) {
        formData.append("thumbnail", this.fields.images.thumbnail.file);
      }

      formData.append(
        "main_image_has_changed",
        this.fields.main_image_has_changed ? 1 : 0
      );
      if (this.fields.images.main.has_changed) {
        formData.append("main_image", this.fields.images.main.file);
      }

      formData.append(
        "featured_thumbnail_has_changed",
        this.fields.images.featured_thumbnail.has_changed ? 1 : 0
      );
      if (this.fields.images.featured_thumbnail.has_changed) {
        formData.append(
          "featured_thumbnail",
          this.fields.images.featured_thumbnail.file
        );
      }

      let payload = {
        appId,
        fields: formData,
        isEditing: false,
      };

      if (this.isEditing) {
        payload.isEditing = true;
        payload.articleId = this.article.id;
      }

      this.$store
        .dispatch("news/saveArticle", payload)
        .then((resp) => {
          this.loading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Article Successfully Saved!",
            color: "success",
          });
          if (this.isEditing === false) {
            this.$router.push({
              name: this.parentRouteName + "-individual",
              params: { articleId: resp.data.article.id },
            });
          }
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
    mainImageHasChanged() {
      this.fields.images.main.has_changed = true;
    },
    thumbnailHasChanged() {
      this.fields.images.thumbnail.has_changed = true;
    },
    featuredThumbnailHasChanged() {
      this.fields.images.featured_thumbnail.has_changed = true;
    },
  },
};
</script>
