<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-craigtoun-events-all' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Events</v-btn
        >
      </v-row>
      <v-row no-gutters align="center" class="mt-0">
        <h1>{{ event.name }}</h1>
      </v-row>
      <v-row>
        <v-col cols="7">
          <v-card v-if="event.is_ticketed">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title> Purchased Tickets </v-toolbar-title>

              <div
                class="input-container flex-grow-1 d-flex align-center ml-8 mr-4"
              >
                <v-text-field
                  label="Search Tickets"
                  v-model="searchTerm"
                  dense
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
                <v-menu
                  offset-y
                  left
                  transition="slide-y-transition"
                  :close-on-click="searchFilters.menu.closeOnClick"
                  :close-on-content-click="
                    searchFilters.menu.closeOnContentClick
                  "
                >
                  <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-btn
                          icon
                          v-on="{ ...onTooltip, ...onMenu }"
                          v-bind="attrsMenu"
                        >
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <span>Filter Search</span>
                    </v-tooltip>
                  </template>
                  <v-card
                    elevation="0"
                    color="grey lighten-5"
                    style="min-width: 200px"
                  >
                    <v-card-subtitle class="d-flex align-center">
                      <div class="font-weight-bold">Filters</div>
                      <v-spacer></v-spacer>
                      <v-btn outlined x-small>Reset</v-btn>
                    </v-card-subtitle>
                  </v-card>
                </v-menu>
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.ticketDialog.open()"
                  >
                    <v-icon small>mdi-ticket-outline</v-icon>
                  </v-btn>
                </template>
                <span>Assign Tickets</span>
              </v-tooltip>
            </v-toolbar>

            <v-divider></v-divider>
            <v-data-table
              :headers="tableHeaders"
              :items="purchasedTickets"
              no-data-text="No Tickets have been Purchased yet"
            >
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-craigtoun-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  >{{ item.customer.full_name }}</router-link
                >
              </template>
              <template v-slot:item.cost="{ item }">
                £{{ item.cost }}
              </template>
              <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.time_block.date) }}
              </template>
              <template v-slot:item.time_block="{ item }">
                {{ item.time_block.formatted_dates.start_time }} -
                {{ item.time_block.formatted_dates.end_time }}
              </template>
              <template v-slot:item.has_collected="{ item }">
                <v-chip label small color="success" v-if="item.has_collected"
                  >Yes</v-chip
                >
                <v-chip label small color="error" v-else>No</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.ticketDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Ticket</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mt-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title> Email Content </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form
                @submit.prevent="saveForm"
                method="post"
                id="message-form"
              >
                <tiptap
                  class="mb-5"
                  label="Message*"
                  v-model="fieldsMessage.message"
                  :error="errors.hasOwnProperty('message')"
                  :error-messages="errors['message']"
                />
              </v-form>

              <v-btn
                color="green"
                text
                type="submit"
                :loading="loading"
                form="message-form"
                >Save</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Sale</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form
                @submit.prevent="saveFormSale"
                method="post"
                id="sale-form"
              >
                <v-switch
                  label="Live for Early Access"
                  v-model="fieldsSale.early_access"
                  inset
                  :color="appColor"
                ></v-switch>
                <v-switch
                  label="Live for General Sale"
                  v-model="fieldsSale.general_sale"
                  inset
                  :color="appColor"
                ></v-switch>
              </v-form>

              <v-btn
                color="green"
                text
                type="submit"
                :loading="loadingSale"
                form="sale-form"
                >Save</v-btn
              >
            </v-card-text>
          </v-card>

          <v-card class="mt-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title> Basic Information</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.eventDialog.open(event)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Event</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Start Date</th>
                  <td>{{ event.formatted_dates.start_date }}</td>
                </tr>
                <tr>
                  <th>Start Time</th>
                  <td>{{ event.start_time }}</td>
                </tr>
                <tr>
                  <th>End Date</th>
                  <td>{{ event.formatted_dates.end_date }}</td>
                </tr>
                <tr></tr>
                <tr>
                  <th>End Time</th>
                  <td>{{ event.end_time }}</td>
                </tr>

                <tr>
                  <th>Is Featured?</th>
                  <td>{{ event.is_featured ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="!isChristmas">
                  <th>Is Ticketed?</th>
                  <td>{{ event.is_ticketed ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="event.is_ticketed">
                  <th>Will Tickets Cost?</th>
                  <td>{{ event.is_paid ? "Yes" : "No" }}</td>
                </tr>
                <tr v-if="event.is_ticketed && event.event.has_allocation">
                  <th>Total Tickets Available</th>
                  <td>{{ event.event.allocation }}</td>
                </tr>

                <tr>
                  <th>Short Description</th>
                  <td>{{ event.short_description }}</td>
                </tr>

                <tr>
                  <th>Description</th>
                  <td><div v-html="event.description"></div></td>
                </tr>
                <tr v-if="event.location">
                  <th>Location</th>
                  <td>{{ event.location }}</td>
                </tr>
                <tr>
                  <th>Draft</th>
                  <td>{{ event.draft ? "Yes" : "No" }}</td>
                </tr>

                <tr v-if="isChristmas">
                  <th>Total Tickets Available</th>
                  <td>{{ totalChristmasAllocation }}</td>
                </tr>
                <tr
                  v-if="
                    isChristmas ||
                    (event.is_ticketed && event.event.has_allocation)
                  "
                >
                  <th>Total Tickets Purchased</th>
                  <td>{{ totalPurchasedTickets }}</td>
                </tr>
                <tr
                  v-if="
                    isChristmas ||
                    (event.is_ticketed && event.event.has_allocation)
                  "
                >
                  <th>Total Tickets Remaining</th>
                  <td>{{ totalRemainingTickets }}</td>
                </tr>
                <tr v-if="event.is_paid && !isChristmas">
                  <th>Ticket Cost For Under 3s</th>
                  <td>
                    £{{
                      event.event.ticket_price_under_3
                        ? event.event.ticket_price_under_3
                        : "0.00"
                    }}
                  </td>
                </tr>
                <tr v-if="event.is_paid && !isChristmas">
                  <th>Ticket Cost For Over 3s</th>
                  <td>
                    £{{
                      event.event.ticket_price_over_3
                        ? event.event.ticket_price_over_3
                        : "0.00"
                    }}
                  </td>
                </tr>
                <tr v-if="isChristmas">
                  <th>Ticket Price</th>
                  <td>
                    £{{
                      event.event.ticket_price
                        ? event.event.ticket_price
                        : "0.00"
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mt-6" v-if="isChristmas">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Time Blocks</v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="timeBlockTableHeaders"
              :items="event.event.time_blocks"
              no-data-text="No Time Blocks have been added yet."
            >
              <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.date) }}
              </template>
              <template v-slot:item.tickets_remaining="{ item }">
                {{ timeBlockTotalRemaining(item) }}/{{ item.allocation }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ticket-dialog
      :event="event"
      :isChristmas="isChristmas"
      ref="ticketDialog"
    />
    <event-dialog ref="eventDialog" />
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";
import EventDialog from "./components/EventDialog.vue";
import TicketDialog from "./components/TicketDialog";

export default {
  props: {
    appColor: {
      type: String,
    },
  },

  components: {
    EventDialog,
    Tiptap,
    TicketDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Events",
          disabled: false,
          to: { name: "module-craigtoun-events-all" },
          exact: true,
        },
      ],
      searchTerm: "",
      fieldsMessage: {
        message: null,
      },
      fieldsSale: {
        early_access: false,
        general_sale: false,
      },
      loading: false,
      loadingSale: false,
      errors: {},
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
      timeBlockTableHeaders: [
        { text: "Date", value: "date" },
        { text: "Start Time", value: "formatted_dates.start_time" },
        { text: "End Time", value: "formatted_dates.end_time" },
        { text: "Tickets Remaining", value: "tickets_remaining" },
      ],
    };
  },

  mounted: function () {
    this.fieldsMessage.message = this.event.email_content;

    this.fieldsSale.early_access = this.event.early_access;
    this.fieldsSale.general_sale = this.event.general_sale;
  },

  computed: {
    event() {
      return this.$store.getters["craigtoun/eventsStore/get"];
    },

    isChristmas() {
      if (!this.event.event) {
        return false;
      }

      return this.event.event.type === "christmas";
    },

    tableHeaders() {
      if (this.isChristmas) {
        return [
          { text: "Customer", value: "customer" },
          { text: "Total Tickets", value: "total_tickets" },
          { text: "Cost", value: "cost" },
          { text: "Date", value: "date" },
          { text: "Time Block", value: "time_block" },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ];
      } else {
        return [
          { text: "Customer", value: "customer" },
          { text: "Total Tickets", value: "total_tickets" },
          { text: "Total Under 3s", value: "total_tickets_under_3" },
          { text: "Total Over 3s", value: "total_tickets_over_3" },
          { text: "Cost", value: "cost" },
          { text: "Collected?", value: "has_collected" },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ];
      }
    },

    purchasedTickets() {
      let tickets = this.event.tickets;

      if (this.searchTerm !== "") {
        tickets = tickets.filter((t) => {
          const customerName = t.customer.full_name.toLowerCase();
          const customerEmail = t.customer.email.toLowerCase();
          const customerPhone = t.customer.phone.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            customerName.includes(searchTerm) ||
            customerEmail.includes(searchTerm) ||
            customerPhone.includes(searchTerm)
          );
        });
      }

      return tickets;
    },

    totalPurchasedTickets() {
      if (this.event.event === {}) {
        return 0;
      }

      if (this.event.event.type === "christmas") {
        let total = 0;

        this.event.tickets.forEach((t) => {
          total += parseInt(t.total_tickets);
        });

        return total;
      } else if (
        this.event.is_paid &&
        this.event.is_ticketed &&
        this.event.event.has_allocation
      ) {
        let total = 0;

        this.event.tickets.forEach((t) => {
          total += parseInt(t.total_tickets_over_3);
        });

        return total;
      }

      return 0;
    },

    totalRemainingTickets() {
      if (this.event.event === {}) {
        return 0;
      }

      if (this.event.event.type === "christmas") {
        return this.totalChristmasAllocation - this.totalPurchasedTickets;
      } else if (
        this.event.is_paid &&
        this.event.is_ticketed &&
        this.event.event.has_allocation
      ) {
        return (
          parseInt(this.event.event.allocation) - this.totalPurchasedTickets
        );
      }

      return 0;
    },

    totalChristmasAllocation() {
      let allocation = 0;

      this.event.event.time_blocks.forEach((tb) => {
        allocation += parseInt(tb.allocation);
      });

      return parseInt(allocation);
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.event.name,
      disabled: true,
    });
  },

  methods: {
    saveFormSale() {
      this.loadingSale = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        eventId: this.$route.params.eventId,
        fields: this.fieldsSale,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("craigtoun/eventsStore/saveEventSale", payload)
        .then(() => {
          this.resetFormSale();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loadingSale = false;
        });
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        eventId: this.$route.params.eventId,
        fields: this.fieldsMessage,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("craigtoun/eventsStore/saveEventEmail", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
    },

    resetFormSale: function () {
      this.loadingSale = false;
      this.isEditing = true;
    },

    timeBlockTotalRemaining(time_block) {
      let total = 0;

      this.event.tickets.forEach((t) => {
        if (t.time_block_id === time_block.id) {
          total += parseInt(t.total_tickets);
        }
      });

      return parseInt(time_block.allocation) - total;
    },
  },
};
</script>
