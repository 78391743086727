import store from "@/stores/";

export default [
  {
    path: "dogs",
    component: () => import("@/modules/lhl/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("lhl/dogs/loadDogs", {
          appId: to.params.id,
        }),
        store.dispatch("lhl/breeds/loadBreeds", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "bitches",
        props: () => ({
          type: "F",
          status: "breeding-dog",
        }),
        name: "module-lhl-dogs-bitches",
        component: () => import("@/modules/lhl/views/dogs/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/dogs/loadDogs", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "studs",
        props: () => ({
          type: "M",
          status: "breeding-dog",
        }),
        name: "module-lhl-dogs-studs",
        component: () => import("@/modules/lhl/views/dogs/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/dogs/loadDogs", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "",
        name: "module-lhl-dogs-all",

        component: () => import("@/modules/lhl/views/dogs/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/dogs/loadDogs", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":dogId",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/dogs/loadDog", {
              appId: to.params.id,
              dogId: to.params.dogId,
            }),
            store.dispatch("lhl/customers/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },

        component: () => import("@/modules/lhl/views/dogs/Individual"),
        children: [
          {
            path: "",
            redirect: {
              name: "module-lhl-dogs-individual",
            },
          },
          {
            path: "details",
            name: "module-lhl-dogs-individual",
            component: () => import("@/modules/lhl/views/dogs/tabs/Details"),
          },

          {
            path: "weight",
            name: "module-lhl-dogs-individual-weight",
            component: () => import("@/modules/lhl/views/dogs/tabs/Weight"),
          },
          {
            path: "health",
            name: "module-lhl-dogs-individual-health",
            component: () => import("@/modules/lhl/views/dogs/tabs/Health"),
          },
          {
            path: "images",
            name: "module-lhl-dogs-individual-images",
            component: () => import("@/modules/lhl/views/dogs/tabs/Images"),
          },
          {
            path: "litters",
            name: "module-lhl-dogs-individual-litters",
            component: () => import("@/modules/lhl/views/dogs/tabs/Litters"),
          },
          {
            path: "offspring",
            name: "module-lhl-dogs-individual-offspring",
            component: () => import("@/modules/lhl/views/dogs/tabs/Offspring"),
          },
          {
            path: "fch",
            name: "module-lhl-dogs-individual-fch",
            component: () => import("@/modules/lhl/views/dogs/tabs/FCH"),
          },

          {
            path: "pregnancy",
            name: "module-lhl-dogs-individual-pregnancy",
            component: () => import("@/modules/lhl/views/dogs/tabs/Pregnancy"),
          },
          {
            path: "bitch",
            name: "module-lhl-dogs-individual-bitch",
            component: () => import("@/modules/lhl/views/dogs/tabs/Bitch"),
          },
          {
            path: "overview",
            name: "module-lhl-dogs-individual-overview",
            component: () => import("@/modules/lhl/views/dogs/tabs/Overview"),
          },
          {
            path: "pedigree",
            name: "module-lhl-dogs-individual-pedigree",
            component: () => import("@/modules/lhl/views/dogs/tabs/Pedigree"),
          },
          {
            path: "seasons",
            name: "module-lhl-dogs-individual-seasons",
            component: () => import("@/modules/lhl/views/dogs/tabs/Seasons"),
          },
          {
            path: "testing",
            name: "module-lhl-dogs-individual-testing",
            component: () => import("@/modules/lhl/views/dogs/tabs/Testing"),
          },
          {
            path: "worming",
            name: "module-lhl-dogs-individual-worming",
            component: () => import("@/modules/lhl/views/dogs/tabs/Worming"),
          },
        ],
      },
    ],
  },
];
