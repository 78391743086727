<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="note-form">
          <v-text-field
            label="name"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-file-input
            v-model="fields.image"
            accept="image/png, image/jpeg"
            placeholder="Choose a image Image"
            prepend-icon="mdi-camera"
            label="Image *"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>

          <v-text-field
            label="Price"
            v-model="fields.price"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>

          <v-text-field
            label="Ref"
            v-model="fields.ref"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('ref')"
            :error-messages="errors['ref']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="note-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        name: null,
        image: null,
        price: null,
        ref: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      types: [
        { label: "Managed Let", name: "Managed Let" },
        { label: "Let Only", name: "Let Only" },
      ],
    };
  },

  computed: {},

  methods: {
    open: function (product = null) {
      if (product !== null) {
        this.product = product;

        this.fields.property_address_line1 = product.property_address_line1;
        this.fields.property_address_line2 = product.property_address_line2;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.productId = this.product.id;
      }

      this.$store
        .dispatch("petroshops/productsStore/saveProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        name: null,
        image: null,
        price: null,
        ref: null,
      };
    },
  },
};
</script>
