import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  communities: [],
  archived: [],
  community: {},
};

// getters
const getters = {
  all: (state) => state.communities,
  archived: (state) => state.archived,
  get: (state) => state.community,
};

// actions
const actions = {
  loadCommunities({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/community/communities`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCommunitiesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadCommunity({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/community/communities/${
          data.communityId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCommunitySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveCommunity({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/community/communities/${
          data.communityId
        }`
      : `${getApiUrl(data.appId)}/module/community/communities`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCommunitySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteCommunity({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/community/communities/${
          data.communityId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCommunitySuccess", {
            communityId: data.communityId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreCommunity({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/community/communities/${
          data.communityId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreCommunitySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadCommunitiesSuccess(state, data) {
    state.communities = data.communities;
    state.archived = data.archived;
  },

  loadCommunitySuccess(state, data) {
    state.community = data.community;
  },

  saveCommunitySuccess(state, data) {
    if (data.isEditing) {
      const index = state.communities.findIndex(
        (p) => p.id === data.community.id
      );
      state.communities.splice(index, 1, data.community);
    } else {
      state.communities.push(data.community);
    }
  },

  restoreCommunitySuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.communityId);
    state.archived.splice(index, 1);
    state.communities.push(data.community);
  },

  deleteCommunitySuccess(state, data) {
    const index = state.communities.findIndex((c) => c.id === data.communityId);
    state.communities.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
