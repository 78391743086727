<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6" class="mb-5 mt-5">
          <v-card outlined class="mb-6">
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>PHOTO ID</th>
                    <th>
                      Document Status:
                      <v-icon :color="iconColor(form.photo_id_status)">
                        {{ imageStatus(form.photo_id_status) }}
                      </v-icon>
                    </th>
                  </tr>
                  <tr
                    v-for="photo in form.photo_id_status_file"
                    v-bind:key="photo.id"
                  >
                    <td>
                      {{ photo.file }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        :href="photo.asset_urls.file"
                        target="_blank"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        @click="openDelete(photo)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="form.photo_id_status_file.length > 0">
                    <td colspan="2" class="text-right">
                      <v-btn
                        color="green lighten-4 green--text mr-2"
                        @click="
                          $refs.verifiedUpload.changeStatusPrompt(
                            'Verified',
                            'photo_id_status'
                          )
                        "
                        >VERIFY</v-btn
                      >
                      <v-btn
                        color="red lighten-4 red--text"
                        @click="
                          $refs.StatusUploadDialog.changeStatusPrompt(
                            'Returned',
                            'photo_id_status'
                          )
                        "
                        >RETURN</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="mb-5 mt-5">
          <v-card outlined class="mb-6" v-if="form.tenant_type == 'student'">
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <!-- <tr>
                    <td colspan="2" align="right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            depressed
                            color="green lighten-4 green--text"
                            v-on="on"
                            @click="$refs.uploadDialog.open()"
                          >
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add File</span>
                      </v-tooltip>
                    </td>
                  </tr> -->
                  <tr>
                    <th>STUDENT ID</th>
                    <th>
                      Document Status:
                      <v-icon :color="iconColor(form.student_id_status)">
                        {{ imageStatus(form.student_id_status) }}
                      </v-icon>
                    </th>
                  </tr>
                  <tr
                    v-for="photo in form.student_id_status_file"
                    v-bind:key="photo.id"
                  >
                    <td>{{ photo.file }}</td>
                    <td class="text-right">
                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        :href="photo.asset_urls.file"
                        target="_blank"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        @click="openDelete(photo)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="form.student_id_status_file.length > 0">
                    <td colspan="2" class="text-right">
                      <v-btn
                        color="green lighten-4 green--text mr-2"
                        @click="
                          $refs.verifiedUpload.changeStatusPrompt(
                            'Verified',
                            'student_id_status'
                          )
                        "
                        >VERIFY</v-btn
                      >
                      <v-btn
                        color="red lighten-4 red--text"
                        @click="
                          $refs.StatusUploadDialog.changeStatusPrompt(
                            'Returned',
                            'student_id_status'
                          )
                        "
                        >RETURN</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-card
            outlined
            class="mb-6"
            v-if="form.tenant_type == 'professional'"
          >
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>3 MONTHS BANK STATEMENTS</th>
                    <th>
                      Document Status:
                      <v-icon :color="iconColor(form.bank_statements_status)">
                        {{ imageStatus(form.bank_statements_status) }}
                      </v-icon>
                    </th>
                  </tr>
                  <tr
                    v-for="photo in form.bank_statements_status_file"
                    v-bind:key="photo.id"
                  >
                    <td>{{ photo.file }}</td>
                    <td class="text-right">
                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        :href="photo.asset_urls.file"
                        target="_blank"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        @click="openDelete(photo)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="form.bank_statements_status_file.length > 0">
                    <td colspan="2" class="text-right">
                      <v-btn
                        color="green lighten-4 green--text mr-2"
                        @click="
                          $refs.verifiedUpload.changeStatusPrompt(
                            'Verified',
                            'bank_statements_status'
                          )
                        "
                        >VERIFY</v-btn
                      >
                      <v-btn
                        color="red lighten-4 red--text"
                        @click="
                          $refs.StatusUploadDialog.changeStatusPrompt(
                            'Returned',
                            'bank_statements_status'
                          )
                        "
                        >RETURN</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="mb-5">
          <v-card outlined class="mb-6" v-if="form.tenant_type == 'student'">
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>UNIVERSITY ACCEPTANCE</th>
                    <th>
                      Document Status:
                      <v-icon :color="iconColor(form.uni_acceptance_status)">
                        {{ imageStatus(form.uni_acceptance_status) }}
                      </v-icon>
                    </th>
                  </tr>
                  <tr
                    v-for="photo in form.uni_acceptance_status_file"
                    v-bind:key="photo.id"
                  >
                    <td>{{ photo.file }}</td>
                    <td class="text-right">
                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        :href="photo.asset_urls.file"
                        target="_blank"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        @click="openDelete(photo)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="form.uni_acceptance_status_file.length > 0">
                    <td colspan="2" class="text-right">
                      <v-btn
                        color="green lighten-4 green--text mr-2"
                        @click="
                          $refs.verifiedUpload.changeStatusPrompt(
                            'Verified',
                            'uni_acceptance_status'
                          )
                        "
                        >VERIFY</v-btn
                      >
                      <v-btn
                        color="red lighten-4 red--text"
                        @click="
                          $refs.StatusUploadDialog.changeStatusPrompt(
                            'Returned',
                            'uni_acceptance_status'
                          )
                        "
                        >RETURN</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-card
            outlined
            class="mb-6"
            v-if="form.tenant_type == 'professional'"
          >
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>3 MONTHS PAYSLIPS & EMPLOYMENT</th>
                    <th>
                      Document Status:
                      <v-icon :color="iconColor(form.payslips_status)">
                        {{ imageStatus(form.payslips_status) }}
                      </v-icon>
                    </th>
                  </tr>
                  <tr
                    v-for="photo in form.payslips_status_file"
                    v-bind:key="photo.id"
                  >
                    <td>{{ photo.file }}</td>
                    <td class="text-right">
                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        :href="photo.asset_urls.file"
                        target="_blank"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        @click="openDelete(photo)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="form.payslips_status_file.length > 0">
                    <td colspan="2" class="text-right">
                      <v-btn
                        color="green lighten-4 green--text mr-2"
                        @click="
                          $refs.verifiedUpload.changeStatusPrompt(
                            'Verified',
                            'payslips_status'
                          )
                        "
                        >VERIFY</v-btn
                      >
                      <v-btn
                        color="red lighten-4 red--text"
                        @click="
                          $refs.StatusUploadDialog.changeStatusPrompt(
                            'Returned',
                            'payslips_status'
                          )
                        "
                        >RETURN</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" class="mb-5">
          <v-card outlined class="mb-6">
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>PREVIOUS / CURRENT LANDLORD REF</th>
                    <th>
                      Document Status:
                      <v-icon :color="iconColor(form.landlord_ref_status)">
                        {{ imageStatus(form.landlord_ref_status) }}
                      </v-icon>
                    </th>
                  </tr>
                  <tr
                    v-for="photo in form.landlord_ref_status_file"
                    v-bind:key="photo.id"
                  >
                    <td>{{ photo.file }}</td>
                    <td class="text-right">
                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        :href="photo.asset_urls.file"
                        target="_blank"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        class="mr-2"
                        @click="openDelete(photo)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="form.landlord_ref_status_file.length > 0">
                    <td colspan="2" class="text-right">
                      <v-btn
                        color="green lighten-4 green--text mr-2"
                        @click="
                          $refs.verifiedUpload.changeStatusPrompt(
                            'Verified',
                            'landlord_ref_status'
                          )
                        "
                        >VERIFY</v-btn
                      >
                      <v-btn
                        color="red lighten-4 red--text"
                        @click="
                          $refs.StatusUploadDialog.changeStatusPrompt(
                            'Returned',
                            'landlord_ref_status'
                          )
                        "
                        >RETURN</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete File</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <status-upload-dialog ref="StatusUploadDialog" />
    <verified-upload ref="verifiedUpload" />
    <upload-dialog ref="uploadDialog" />
  </div>
</template>

<script>
import StatusUploadDialog from "./components/StatusUploadDialog.vue";
import VerifiedUpload from "./components/VerifiedUpload.vue";
import UploadDialog from "./components/UploadDialog.vue";

export default {
  components: {
    StatusUploadDialog,
    VerifiedUpload,
    UploadDialog,
  },
  data() {
    return {
      deleteDialog: {
        open: false,
        loading: false,
        file: {},
      },
    };
  },
  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },

  methods: {
    openDelete(file) {
      this.deleteDialog.file = file;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.file = {};
    },

    saveDelete() {
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/deleteFile", {
          appId: this.$route.params.id,
          formId: this.$route.params.formId,
          fileId: this.deleteDialog.file.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteDialog.loading = false;
        });
    },

    iconColor(status) {
      if (status == null) {
        return "red";
      } else if (status == "Verified") {
        return "blue";
      } else if (status == "Sent Back" || status == "Returned") {
        return "orange";
      } else {
        return "green";
      }
    },

    imageStatus(status) {
      if (status == null || status == "Returned") {
        return "mdi-close-circle";
      } else if (status == "Verified") {
        return "mdi-check-circle";
      } else if (status == "Sent Back") {
        return "mdi-alert-circle";
      } else {
        return "mdi-check-circle";
      }
    },
  },
};
</script>
