<template>
  <v-badge color="error" :content="this.countGlobal" v-if="this.countGlobal > 0"
    ><slot></slot>
  </v-badge>
  <div v-else><slot></slot></div>
</template>

<script>
export default {
  props: {
    appId: {
      type: Number,
      required: true,
    },
    moduleName: {
      type: String,
    },
  },

  data() {
    return {
      count: this.loadCount(),
      loading: true,
    };
  },

  mounted() {
    this.loadCount();
  },

  computed: {
    countGlobal() {
      return this.$store.getters["getCount"];
    },
  },

  methods: {
    loadCount() {
      const appId = this.appId;
      const moduleName = this.moduleName;

      this.$store
        .dispatch("loadToCount", {
          appId,
          moduleName,
        })
        .then((count) => {
          this.$store.commit("setCount", count);
        });
    },
  },
};
</script>
