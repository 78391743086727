import store from "@/stores/";

export default [
  {
    path: "projects",
    component: () => import("@/modules/projects/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("workload/loadModule", {
          appId: to.params.id,
          module: "workload",
        }),
        store.dispatch("customers/loadModule", {
          appId: to.params.id,
          module: "customers",
        }),
        store.dispatch("team/loadTeamMembers", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-projects",
        component: () => import("@/modules/projects/views/Dashboard"),
      },
    ],
  },
];
